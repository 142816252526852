import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import ProductOnList from "../components/productList/ProductOnList";
import ShopHeader from "../components/headers/ShopHeader";
import InfoButton from "../components/general/InfoButton";
import MobileInfo from "../components/productList/mobileInfo";
import MobileMenu from "../components/general/MobileMenu";
import { connect } from "react-redux";
import { isMobileFetched, cartFetched, userFetched, currentCategoryFetched, searchValueFetched } from "../actions";
import ListingPage from "./ListingPage";
import Request from "../api/Request";
import Price from "../components/general/Price";
import  { ReactComponent as ArrowLeft } from '../svg/arrowLeft.svg';

class ProductListPage extends ListingPage {
  constructor(props) {
    super(props);
    this.state = {
      showTopInfo: false,
      showMobileInfo: false,
      timeOut: null,
      addedProductName: '',
      addedQuantity: '',
      cartSum: 0,
      //to powinien być pusty araj
      products: [],
    };
  }

  //component didMpout i ustawić sobie stan(wpisać to araya )
  componentDidMount() {
    this.setState({ user: this.props.user });
    Request.getProducts(response => {
      if (response.success) {
        this.setState({ products: response.products });
      }
    });
  }

  hideTopInfo() {
    this.setState({
      timeOut: setTimeout(() => this.setState({ showTopInfo: false }), 3000),
    });
  }

  stopHideTopInfo() {
    clearTimeout(this.state.timeOut);
    this.setState({ showTopInfo: true });
  }

  showMobileInfo() {
    this.setState({ showMobileInfo: true });
  }

  hideMobileInfo() {
    this.setState({ showMobileInfo: false });
  }

  addToCart(product, quantity) {
    const cart = this.props.cart;
    product.quantity = quantity;
    let found = false;

    cart.forEach((element, id) => {
      if (element.id == product.id) {
        found = true;
        cart[id].quantity += product.quantity;
      }
    });

    if(!found){
      cart.push({...product});
    }
    this.props.cartFetched(cart);
  }

  rawTotal(){
    let total = 0;
    const products = this.props.cart;
    for(let i=0; i < products.length; i++){
        total = total + products[i].price * products[i].quantity;
    }

    return total;
  }

  discountPercentage(){
    let total = this.rawTotal();
    let currentDiscount = 0;

    if (this.props.user.discount) {
      this.props.user.discount.forEach(discount => {
        if(currentDiscount < discount.percentage && total >= discount.price){
          currentDiscount = discount.percentage;
        }
      });
    }
    return currentDiscount;
  }
  
  priceSum(){
    const total = this.rawTotal();
    const discount = this.discountPercentage();

    if(discount){
      return total * ((100 - discount) * 0.01);
    }

    return  total;
  }

  topInfoData(product, quantity){
    this.setState({ addedQuantity: quantity, addedProductName: product.name, })
  }

  saveMyData(){
    this.setState({error: false});
    Request.saveMyData(this.state.user, response => {
      if (response.success) {
        this.props.userFetched(this.state.user);
      } else {
        this.setState({error: true});
      }
    })
  }

  zlotyOnClick(){
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        currency: 'PLN',
      }
    }) , 

    () => {
      this.props.userFetched(this.state.user);
      this.saveMyData();
     });
  }

  dolarOnClick(){
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        currency: 'USD',
      }
    }),
    () => {
      this.props.userFetched(this.state.user);
      this.saveMyData();
     }
    );
  }

  euroOnClick(){
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        currency: 'EUR',
      }
    }),
     () => {
       this.props.userFetched(this.state.user);
       this.saveMyData();
      }
    );
  }

  render() {
    const columnHeader = {
      fontSize: "16px",
      fontFamily: "Lato",
      fontWeight: "600",
    };

    const currencyButton = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 1px",
      padding: "3px 10px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      marginRight: "10px",
      fontSize: "14px",
      width: "30px",
      height: "30px"
    };

    const currencyButtonActive = {
      color: colors.white,
      borderColor: colors.blue,
      backgroundColor: colors.blue,
      border: "solid 1px",
      padding: "3px 10px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      marginRight: "10px",
      fontSize: "14px",
      width: "30px",
      height: "30px"
    };

    const showTopInfoClass = this.state.showTopInfo
      ? "topInfo"
      : "topInfo  topInfoHidden";

    return (
      <Container style={{ maxWidth: "100vw" }}>
        <ShopHeader cartLength={this.props.cart.length} />
        <Row
          style={
            this.props.isMobile
              ? { display: " none" }
              : { marginBottom: "20px", marginTop: "50px" }
          }
        >
          <Col>
            <Row>
              <Col md="2"></Col>
              <Col md="7">
                <h4 style={columnHeader}>Product</h4>
              </Col>
              <Col md="3">
                <h4 style={columnHeader}>Stock Quantity</h4>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <h4 style={columnHeader}>Your Order</h4>
              </Col>
              <Col>
                <h4 style={columnHeader}>Your Discount</h4>
              </Col>
              <Col>
                <h4 style={columnHeader}>Price</h4>
              </Col>
              <Col>
                <div style={{ marginTop: "-5px", position: 'relative', left:'-15%' }}>
                  <Button onClick={this.zlotyOnClick.bind(this)} style={this.props.user.currency == 'PLN' ? currencyButtonActive : currencyButton} variant="outline-info">
                    zł
                  </Button>
                  <Button onClick={this.dolarOnClick.bind(this)} style={this.props.user.currency == 'USD' ? currencyButtonActive : currencyButton} variant="outline-info">
                    $
                  </Button>
                  <Button onClick={this.euroOnClick.bind(this)} style={this.props.user.currency == 'EUR' ? currencyButtonActive : currencyButton} variant="outline-info">
                    €
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={this.props.isMobile ? { marginBottom: "50px" } : {}}>
          {this.state.products.filter( this.props.searchValue != '' ? product => product.name.toLowerCase().includes(this.props.searchValue) : product => product.category_id == this.props.currentCategory).map((product, id) => {
            return (
              <ProductOnList
                key={id}
                name={product.name}
                image={product.small_image}
                stock={product.stock}
                cart={this.props.cart}
                discount={this.discountPercentage()}
                price={product.price}
                productId={product.id}
                mobile={this.props.isMobile}
                showTopInfo={(quantity) => {
                  this.stopHideTopInfo();
                  this.hideTopInfo();
                  this.showMobileInfo();
                  this.topInfoData(product, quantity);
                  this.addToCart(product, quantity);
                }}
              />
            );
          })}
        </div>
        {this.props.isMobile ? (
          <MobileInfo
            class={this.state.showMobileInfo}
            hideMobileInfo={this.hideMobileInfo.bind(this)}
            product={this.state.addedProductName}
            quantity={this.state.addedQuantity}
            cartSum={this.priceSum()}
          />
        ) : (
          <div
            className={showTopInfoClass}
            style={{
              position: "fixed",
              top: "60px",
              backgroundColor: colors.blue,
              padding: "15px",
              width: "85.5%",
              textAlign: "center",
              borderRadius: "5px",
              left: "9.5%",
              transition: "1s",
            }}
          >
            <h3
              style={{
                fontSize: "16px",
                fontFamily: "lato",
                color: colors.white,
              }}
            >
              You have added {this.state.addedProductName} in quantity of {this.state.addedQuantity} to cart. Your
              cart value is <Price price={this.priceSum()}/>.{" "}
            </h3>
          </div>
        )}
        {this.props.isMobile ? <></> : <InfoButton />}
        <Row style={this.props.isMobile ? { display: "none" } : {}}>
          <Col>
            <Link to="/">
              <ArrowLeft
                style={{ position: "fixed", top: "120px" }}
              />
            </Link>
          </Col>
        </Row>
        {this.props.isMobile ? <div>{this.state.showMobileInfo ? <></> : <MobileMenu /> }</div> : <></>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
    currentCategory: state.currentCategory,
    searchValue: state.searchValue,
  };
};
const mapDispatchToProps = { isMobileFetched, cartFetched, userFetched, currentCategoryFetched, searchValueFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ProductListPage);
