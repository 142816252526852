import React from "react";
import colors from "../../colors/colors";
import {Link} from "react-router-dom";

export default class SingleOrder extends React.Component {
  render() {
    return (
      <Link to="/order" style={{textDecoration: 'none', color: '#000', fontFamily: 'lato', }}>
        <div
          onClick={this.props.onClick}
          style={{
            height: "45px",
            border: "solid 1px",
            borderColor: colors.blue,
            borderRadius: "5px",
            marginBottom: "16px",
            boxShadow: "0 0 0 0.2rem #c2d5dd",
          }}
        >
          <p style={{ textAlign: "center", position: "relative", top: "9px",}}>
            {this.props.order}
          </p>
        </div>
      </Link>
    );
  }
}
