import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import CheckoutHeader from "../components/headers/CheckoutHeader";
import InfoButton from "../components/general/InfoButton";
import MobileMenu from "../components/general/MobileMenu";
import { connect } from "react-redux";
import { isMobileFetched, deliveryInfoFetched, cartFetched, lastOrderIdFetched } from "../actions";
import Request from "../api/Request";
import {
  withRouter
} from 'react-router-dom';
import  { ReactComponent as ArrowLeft } from '../svg/arrowLeft.svg';

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTopInfo: false,
      headerID: 3,
      checkoutPage: true,
    };
  }

  componentDidMount(){
    if(Object.keys(this.props.cart).length === 0){
      this.props.history.push('/');
    }
  }

  hideTopInfo() {
    this.setState({
      showTopInfo: true,
      timeOut: setTimeout(() => this.setState({ showTopInfo: false }), 3000),
    });
  }

  uploadOrder() {
    Request.createOrder({ cart: this.props.cart, shipping: this.props.deliveryInfo }, response => {
      if (response.success) {
        this.props.cartFetched([]);
        this.props.lastOrderIdFetched(response.orderId);
        this.props.history.push('/thanks');
      }else{
        this.hideTopInfo();
      }
    });
  }

  render() {

    const title = {
      color: colors.blue,
      fontSize: "24px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
      marginTop: "30px",
      marginBottom: "30px"
    };

    const button = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 3px",
      padding: "3px 30px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      marginTop: "20vh",
      marginBottom: "30px"
    };

    const buttonMobile = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 1px",
      padding: "0px 20px",
      borderRadius: "1px",
      fontFamily: "lato",
      fontWeight: "600",
      fontSize: "14px",
      margiTop: "11px"
    };

    const showTopInfoClass = this.state.showTopInfo
      ? "topInfo"
      : "topInfo  topInfoHidden";

    return (
      <Container style={{ maxWidth: "100vw" }}>
        <CheckoutHeader headerID={this.state.headerID} />
        <Row>
          <Col style={this.props.isMobile ? {} : { margin: "0 8%" }}>
            <h3 style={title}>Payment</h3>
          </Col>
        </Row>
        <Row style={this.props.isMobile ? {} : { margin: "0 9% 0 7%" }}>
          <Col>
            <p>Simply confirm your order and leave logistics to us.</p>
            <p>As agreed, we will send confirmation with final invoice and dedicated payment term.</p>
            <p>See you soon,</p>
            <p>Fräya Team</p>
          </Col>
        </Row>
        <Row style={this.props.isMobile ? { marginBottom: '50px' } : { margin: "30px 8% 0  8%" }}>
          <Col style={{ textAlign: "center" }}>
            <Button
              onClick={this.uploadOrder.bind(this)}
              style={this.props.mobile ? buttonMobile : button}
              variant="outline-info"
            >
              CONFIRM YOUR ORDER
            </Button>
          </Col>
        </Row>
        <div
            className={showTopInfoClass}
            style={{
              position: "fixed",
              top: "60px",
              backgroundColor: 'red',
              padding: "15px",
              width: "85.5%",
              textAlign: "center",
              borderRadius: "5px",
              left: "9.5%",
              transition: "1s",
            }}
          >
            <h3
              style={{
                fontSize: "16px",
                fontFamily: "lato",
                color: colors.white,
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </h3>
          </div>
        {this.props.isMobile ? <></> : <InfoButton />}
        <Row>
          <Col>
            <Link to='/delivery'>
              <ArrowLeft
                style={this.props.isMobile ? { display: 'none' } : { position: "fixed", top: "120px" }}
              />
            </Link>
          </Col>
        </Row>
        {this.props.isMobile ? <MobileMenu checkoutPage={this.state.checkoutPage} /> : <></>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    deliveryInfo: state.deliveryInfo,
    cart: state.cart,
    lastOrderId: state.lastOrderId,
  };
};
const mapDispatchToProps = { isMobileFetched, deliveryInfoFetched, cartFetched, lastOrderIdFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentPage));
