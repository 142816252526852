import React from "react";
import { Form, Button } from "react-bootstrap";
import colors from "../../colors/colors";
import Request from "../../api/Request";

export default class ForgotPasswoord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
      email: '',
      error: false,
    }
  }
  recover(event) {
    this.setState({error: false});
    event.preventDefault();
    Request.resetPassword({email: this.state.email}, response => {
      if(response.success){
        this.setState({ showMessage: true });
      }else{
        this.setState({error: true});
      }
    });
  }
  render() {
    return (
      <div style={{
        position: 'relative',
        top: '-43px',
      }}>
        <h2
          style={{
            color: colors.white,
            fontSize: "22px",
            fontFamily: "Lato",
            fontWeight: "300",
            textAlign: "left",
            marginBottom: "30px"
          }}
        >
          Recover Password.
        </h2>
        {this.state.showMessage ? <p style={{
            color: colors.white,
            fontSize: "16px",
            fontFamily: "Lato",
            fontWeight: "300",
            textAlign: "center",
          }} >Check your inbox</p> : <Form onSubmit={this.recover.bind(this)}>
          <Form.Group className="loginInput" controlId="loginEmail">
            <Form.Label
              style={{
                color: colors.white,
                fontSize: "16px",
                fontFamily: "Lato",
                fontWeight: "300",
                textAlign: "left"
              }}
            >
              E-mail address
            </Form.Label>
            <Form.Control
              type="email"
              value={this.state.email}
              onChange={e => this.setState({email: e.target.value})}
            />
          </Form.Group>
          <Button
            style={{
              backgroundColor: colors.white,
              color: colors.blue,
              height: "30px",
              width: "100%",
              borderRadius: "5px",
              fontFamily: "Lato",
              border: "solid 1px white",
              fontWeight: "800",
              fontSize: "16px",
              marginTop: "10px",
              padding: '0',
            }}
            variant="primary"
            type="submit"
          >
            Reset Password
          </Button>
        </Form>}

        {this.state.showMessage ? 
        <Button
        onClick={this.props.forgotPasswordClick}
        style={{
          backgroundColor: colors.white,
          color: colors.blue,
          height: "30px",
          width: "100%",
          borderRadius: "5px",
          fontFamily: "Lato",
          border: "solid 1px white",
          fontWeight: "800",
          fontSize: "16px",
          marginTop: "10px",
          padding: '0',
        }}
        variant="primary"
        type="submit"
      >
        Log in
      </Button> : 
        <p
          onClick={this.props.forgotPasswordClick}
          style={{
            color: colors.white,
            fontSize: "16px",
            fontFamily: "Lato",
            fontWeight: "300",
            textAlign: "center",
            cursor: 'pointer'
          }}
        >
          Log in
        </p> }
      </div>
    );
  }
}
