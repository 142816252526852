// const apiUrl = 'http://localhost:8080/';
// const apiUrl = 'http://frayaserverlukasz/';
const apiUrl = 'https://server.frayawarehouse.com/';
let bearer = '';
const get = (callback, url) => {
    return request(callback, {}, url, 'GET');
}

const post = (callback, params, url, authenticated, noLoader) => {
    return request(callback, params, url, 'POST', authenticated, noLoader);
}

const request = (callback, params, url, method, authenticated, noLoader) => {
    if (!noLoader) {
    let loader = document.getElementById('loader');
    loader.classList.add("loadderActive");
    }
    if (typeof authenticated === 'undefined') {
        authenticated = true;
    }
    const requestParams = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };
    if (authenticated) {
        requestParams.headers['Authorization'] = 'Bearer ' + bearer;
    }
    if (method == 'POST') {
        requestParams.body = JSON.stringify(params);
    }
    fetch(
        apiUrl + url,
        requestParams
    ).then(response => {
        if (response && response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            // response.json().then(data => console.log(data));
            return [];
        }
    }
    ).then(response => {
        //wyłącz loader
        document.getElementById('loader').classList.remove("loadderActive");
        callback(response);
    }).catch(error => console.error('Error:', error));
}

const Request = {
    login(email, password, callback) {
        post(response => {
            if (response.success) {
                bearer = response.access_token;
            }
            callback(response);
        }, { email: email, password: password }, 'auth/login', false);
    },
    getProducts(callback) {
        get(response => {
            callback(response);
        }, 'product/get-all?frontend=1');
    },
    saveMyData(data, callback) {
        post(response => {
            callback(response);
        }, data, 'user/update');
    },
    resetPassword(data, callback) {
        post(response => {
            callback(response);
        }, data, 'auth/reset-password');
    },
    doResetPassword(data, callback) {
        post(response => {
            callback(response);
        }, data, 'auth/do-reset-password');
    },
    getOrderHistory(callback) {
        get(response => {
            callback(response);
        }, 'order/history');
    },
    createOrder(data, callback) {
        post(response => {
            callback(response);
        }, data, 'order/create');
    },
    orderFeedback(data, callback) {
        post(response => {
            callback(response);
        }, data, 'order/feedback');
    },
    getCategories(callback) {
        get(response => {
            callback(response);
        }, 'category/get-all?frontend=1');
    },
    realProductImage(url) {
        if (!url) {
            return url;
        }
        if (url.includes('http')) {
            return url;
        }
        return apiUrl + '/uploads/' + url;
    },
    getSearchHints(query, callback){
        post(response => {
            callback(response);
        }, {query: query}, 'product/hint', true, true);
    }
};

export default Request;