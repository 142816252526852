import React from "react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import LoginPage from "./pages/LoginPage";
import ProductListPage from "./pages/ProductListyPage";
import "bootstrap/dist/css/bootstrap.min.css";
import YourCart from "./pages/YorCartPage";
import DeliveryPage from "./pages/DeliveryPage";
import PaymentPage from "./pages/PaymentPage";
import ThankYouPage from "./pages/ThankYouPage";
import MyAcountPage from "./pages/MyAcountPage";
import SingleOrderPage from "./pages/SingleOrderPage";
import HelloPage from "./pages/HelloPage";
import { connect } from "react-redux";
import { isMobileFetched, userFetched } from "./actions";
import CookieBar from "./components/general/CookieBar";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiesInformation from "./pages/CookiesInformation";
import LoadingLogo from './img/LoaderLogo.png';
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     acceptCookies: false,
    };
  }

  componentDidMount() {
    this.props.isMobileFetched(window.innerWidth < 900);
    let cookies = JSON.parse(window.localStorage.getItem('cookies'));
    if(cookies){
      this.setState({acceptCookies: true});
    }
  }

  setAcceptCookies(){
    this.setState({acceptCookies: true});
    window.localStorage.setItem('cookies', JSON.stringify(true));
  }

  render() {
    return (
      <Router>
        {this.props.user.hasOwnProperty('name') ? (
          <div>
            <Route path="/products" component={ProductListPage} />
            <Route path="/cart" component={YourCart} />
            <Route path="/delivery" component={DeliveryPage} />
            <Route path="/payment" component={PaymentPage} />
            <Route path="/thanks" component={ThankYouPage} />
            <Route path="/acount" component={MyAcountPage} />
            <Route path="/order" component={SingleOrderPage} />
            <Route exact path="/" component={HelloPage} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/cokies-information" component={CookiesInformation} />
            <Route path="/change-password" component={ChangePasswordPage} />
          </div>
        ) : (
          <div>
            <Switch>
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/cokies-information" component={CookiesInformation} />
            <Route path="/reset-password" component={ResetPasswordPage} />
            <Route  component={LoginPage} />
            </Switch>
          </div>
        ) }
        {this.state.acceptCookies ? <></> : <CookieBar setAcceptCookies={this.setAcceptCookies.bind(this)} />  }
        <div id="loader">
          <img className="loaderImage" src={LoadingLogo} />
         </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    user: state.user,
  };
};
const mapDispatchToProps = { isMobileFetched, userFetched }; // (2)

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App); // (3)
