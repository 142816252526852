import React from "react";
import { Button } from "react-bootstrap";
import colors from "../../colors/colors";


export default class ConfirmButton extends React.Component {
  render() {
    return (
      <Button 
      onClick={this.props.onClick}
      type={'button'}
      style={{
          backgroundColor: colors.white, 
          color: colors.blue, 
          height: '30px', 
          width: '100%', 
          borderRadius: '5px', 
          fontFamily: 'Lato', 
          border: 'solid 1px white', 
          fontWeight: '800',
          fontSize: '16px',
          marginTop: '10px',
          padding: '0'}}  variant="primary" >
        Confirm
      </Button>
    );
  }
}
