import React from 'react';
import UniversalInput from '../addressAndDelivery/UniversalInput';
import { Row, Col } from 'react-bootstrap';
import CountrySelect from '../addressAndDelivery/CountrySelect';

export default class ShippingSettings extends React.Component{
    render(){
        return(
            <>
            <UniversalInput
              placeholder="Name"
              label="Name"
              id="ShippingName"
              value={this.props.user.shipping.name}
              handleInputChange={this.props.handleShippingNameChange}
              isError={this.props.hasBeenSubmited && (this.props.user.shipping.name == null || this.props.user.shipping.name.length < 2)}
              errorMessage='This field cannot be empty'
            />
            <UniversalInput
              placeholder="CompanyName"
              label="Company Name"
              id="ShippingCompanyName"
              value={this.props.user.shipping.company_name}
              handleInputChange={this.props.handleShippingCompanyNameChange}
              isError={this.props.hasBeenSubmited && (this.props.user.shipping.company_name == null || this.props.user.shipping.company_name.length < 2)}
              errorMessage='This field cannot be empty'
            />
            <UniversalInput
              placeholder="Address"
              label="Address"
              id="ShippingAddress"
              value={this.props.user.shipping.address}
              handleInputChange={this.props.handleShippingAddressChange}
              isError={this.props.hasBeenSubmited && (this.props.user.shipping.address == null || this.props.user.shipping.address.length < 2)}
              errorMessage='This field cannot be empty'
            />
            <Row>
              <Col>
                <CountrySelect
                  value={this.props.user.shipping.country}
                  handleInputChange={this.props.handleShippingCountryChange}
                />
              </Col>
              <Col>
                <UniversalInput
                  placeholder="Postal Code"
                  label="Postal Code"
                  id="ShippingPostalCode"
                  value={this.props.user.shipping.post_code}
                  handleInputChange={this.props.handleShippingPostalChange}
                  isError={this.props.hasBeenSubmited && (this.props.user.shipping.post_code == null || this.props.user.shipping.post_code.length < 2)}
                  errorMessage='This field cannot be empty'
                />
              </Col>
            </Row>
          </>
        )
    }
}