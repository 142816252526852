import React from 'react';
import { Row, Col } from "react-bootstrap";
import {Link} from "react-router-dom";
import colors from '../../colors/colors';
import { Circle, CircleFill } from 'react-bootstrap-icons';
import SearchScreen from '../general/SearchScreen';
import { connect } from "react-redux";
import { isMobileFetched, cartFetched } from "../../actions";
import  { ReactComponent as Sygnet } from '../../svg/sygnet.svg'
import  { ReactComponent as CartActive } from '../../svg/cartActive.svg'
import  { ReactComponent as Search } from '../../svg/search.svg'
import  { ReactComponent as User } from '../../svg/user.svg'

class CheckoutHeader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          isMobile: false,
          showSearch: false,
        };
      }

      hideSearchScreen() {
        this.setState({ showSearch: false });
      }

      showSearchScreen() {
        this.setState({ showSearch: true });
      }

    render(){

        const icon = {
            width: '25px',
            marginTop: '25px'
        }

        const menuItem = {
            color: colors.blue,
            fontSize: "24px",
            fontFamily: "Lato",
            fontWeight: "300",
            textAlign: "center",
        }

        const circle = {
            position: 'relative', 
            top: '-15px', 
            width: '10px', 
            backgroundColor: colors.white,
        }

        const cartNumber = {
            border: 'solid #357490 2px',
            fontSize: '10px',
            borderRadius:'28px',
            textAlign: 'center',
            letterSpacing: 0,
            fontWeight: 'bold',
            width: '20px',
            position: 'relative',
            top: '-30px',
            left: '15px',
            background: colors.white,
            color: colors.blue,
          }

        return(
            <Row>
                <Col md='2' style={this.props.isMobile ? {textAlign: 'center'} : {}}><Link to='/'><Sygnet style={this.props.isMobile ? {marginTop: '10px', width: '30px'} : {marginTop: '10px'}}/></Link></Col>
                <Col style={this.props.isMobile ? {display: 'none'} : {marginTop: '25px'}}>
                    <Row style={{margin: '0 150px'}}>
                        <Col md='3'><h3 style={menuItem}>Your cart</h3></Col>
                        <Col md='6'><h3 style={menuItem}>Address and delivery</h3></Col>
                        <Col md='3'><h3 style={menuItem}>Payment</h3></Col>
                    </Row>
                    <Row style={{margin: '0 150px', border: 'solid', borderColor: colors.blue, borderWidth: '2px 0 0 0 '} }>
                        <Col md='3' style={{textAlign:'center', color: colors.blue}}>{this.props.headerID == 1 ? <CircleFill style={circle}/> : <Circle  style={circle}/> }</Col>
                        <Col md='6' style={{textAlign:'center', color: colors.blue}}>{this.props.headerID == 2 ? <CircleFill style={circle}/> : <Circle  style={circle}/> }</Col>
                        <Col md='3' style={{textAlign:'center', color: colors.blue}}>{this.props.headerID == 3 ? <CircleFill style={circle}/> : <Circle  style={circle}/> }</Col>
                    </Row>
                </Col>
                <Col md='2' style={this.props.isMobile ? {display: 'none'} : {minWidth: '210px'}}>
                    <Row style={{marginLeft: '30px'}}>
                        <Col><Search onClick={this.showSearchScreen.bind(this)} style={icon}/></Col>
                        <Col><Link className='cartNumber' to='cart'><CartActive style={icon}/>{this.props.cart.length > 0 ? <p style={cartNumber}>{this.props.cart.length}</p> : <></>}</Link></Col>
                        <Col><Link to='acount'><User style={icon}/></Link></Col>
                    </Row>
                </Col>
                <SearchScreen 
                showSearch={this.state.showSearch} 
                hide={this.hideSearchScreen.bind(this)}/>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      isMobile: state.isMobile, // (1)
      cart: state.cart,
    };
  };
  const mapDispatchToProps = { isMobileFetched, cartFetched }; // (2)
  export default connect(mapStateToProps, mapDispatchToProps)(CheckoutHeader);