import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";

export default class CookieBar extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100vw",
          position: "fixed",
          bottom: "0",
          backgroundColor: colors.white,
        }}
      >
        <Row>
          <Col md={10} xs={9}>
              <p style={{textAlign: 'center', padding: "15px", paddingBottom: '0px', margin: '0' }}>I accept 
              <Link style={{color: colors.blue}} to='/terms-and-conditions'> Term & Conditions</Link>,
              <Link style={{color: colors.blue}} to='/cokies-information'> Cookies </Link> and 
              <Link style={{color: colors.blue}} to='/privacy-policy'> Pivacy Policy</Link>
              </p>
          </Col>
          <Col md={2} xs={3}>
            <Button style={{margin: '10px'}} variant="dark" onClick={this.props.setAcceptCookies}>OK</Button>
          </Col>
        </Row>
      </div>
    );
  }
}
