import React, { useState } from "react";
import { Form, Row, Col, Image, Container } from "react-bootstrap";
import {Link} from "react-router-dom";
import LoginInput from "../components/login/LoginInput";
import PasswordInput from "../components/login/PasswordInput";
import LoginButton from "../components/login/Button";
import ForgotPassword from "../components/login/ForgotPassword";
import colors from "../colors/colors";
import frayaLogo from "../img/Fraya-Logo.png";
import logoMobile from "../img/Logo-mobile.png";
import { connect } from "react-redux";
import { isMobileFetched, userFetched, cartFetched} from "../actions";
import Request from "../api/Request";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginInputValue: "",
      passwordInputValue: "",
      showPassword: true,
      forgotPassword: true,
      isMobile: false,
    };
  }

  handleLoginChange(event) {
    const { value } = event.target;
    this.setState({ loginInputValue: value });
  }

  handlePasswordChange(event) {
    const { value } = event.target;
    this.setState({ passwordInputValue: value });
  }

  eyeClick() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  forgotPasswordClick() {
    this.setState({ forgotPassword: !this.state.forgotPassword });
  }

  logIn(){
    this.setState({error: false});
    Request.login(this.state.loginInputValue, this.state.passwordInputValue, response => {
      if (response.success) {
        this.props.userFetched({...response.me});
        if(JSON.parse(window.localStorage.getItem('cart'))){this.props.cartFetched(JSON.parse(window.localStorage.getItem('cart')));}
      } else {
        this.setState({error: true});
      }
    });
  }

  render() {
    return (
      <Container style={{ maxWidth: "100vw" }}>
        <Row
          style={
            this.props.isMobile
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colors.blue,
                  height: "100vh",
                  padding: "50px"
                }
              : {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colors.blue,
                  height: "100vh"
                }
          }
        >
          <Col md='4' sm='12' style={this.props.isMobile ? {minHeight: '170px'} : {}}>
            <Image
              src={this.props.isMobile ? logoMobile : frayaLogo}
              style={
                this.props.isMobile
                  ? {
                      position: "fixed",
                      width: "115px",
                      top: "40px",
                      left: "calc(50% - 60px)"
                    }
                  : {
                      position: "fixed",
                      top: "40px",
                      left: "40px"
                    }
              }
            />
          </Col>
          <Col  md='4' sm='12' >
            <Row>
              <Col md="2"></Col>
              <Col>
                {this.state.forgotPassword ? (
                  <div style={{ textAlign: "center" }}>
                    <h2
                      style={{
                        color: colors.white,
                        fontSize: "21px",
                        fontFamily: "Lato",
                        fontWeight: "300",
                        textAlign: "left",
                        marginBottom: "30px"
                      }}
                    >
                      Please log in to continue.
                    </h2>
                    <Form onSubmit={this.logIn.bind(this)}>
                      <LoginInput
                        login={this.state.loginInputValue}
                        handleInputChange={this.handleLoginChange.bind(this)}
                      />
                      <PasswordInput
                        type={this.state.showPassword}
                        password={this.state.passwordInputValue}
                        handleInputChange={this.handlePasswordChange.bind(this)}
                        eyeClick={this.eyeClick.bind(this)}
                      />
                      <LoginButton onClick={this.logIn.bind(this)} />
                      {this.state.error && <p style={{color : 'red'}}>Invalid credentials</p>}
                      <p
                        onClick={this.forgotPasswordClick.bind(this)}
                        style={{
                          color: colors.white,
                          fontSize: "16px",
                          fontFamily: "Lato",
                          fontWeight: "300",
                          textAlign: "center",
                          cursor: "pointer",
                          marginTop: "20px"
                        }}
                      >
                        Forgot password?
                      </p>
                    </Form>
                  </div>
                ) : (
                  <ForgotPassword
                    forgotPasswordClick={this.forgotPasswordClick.bind(this)}
                  />
                )}
              </Col>
              <Col md="2"></Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    user: state.user,
    cart: state.cart,
  };
};
const mapDispatchToProps = { isMobileFetched, userFetched, cartFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);