import React from "react";
import { Container, Row, Col,} from "react-bootstrap";
import colors from "../colors/colors";
import EmptyHeader from "../components/headers/EmptyHeader";
import InfoButton from "../components/general/InfoButton";
import MobileMenu from "../components/general/MobileMenu";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";
import Cookies from "../pdf/Cookies.pdf";

class CookiesInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      stars: 0,
    };
  }

  sending() {
    this.setState({ sent: true });
  }

  setStars(number) {
    this.setState({ stars: number });
  }

  render() {
    const title = {
      color: colors.blue,
      fontSize: "24px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
      marginTop: "30px",
      marginBottom: "30px",
    };

    return (
      <Container style={{ maxWidth: "100vw" }}>
        <EmptyHeader />
        <Row>
          <Col style={this.props.isMobile ? {} : { margin: "0 8%" }}>
            <h3 style={title}>Cookies information</h3>
          </Col>
        </Row>
        <Row>
          <Col style={this.props.isMobile ? {} : { margin: "0 8%" }}>
            <iframe
              src={Cookies}
              width="100%"
              height="500px"
              style={{marginBottom: '50px'}}
            ></iframe>
          </Col>
        </Row>
        {this.props.isMobile ? <MobileMenu /> : <></>}
        {this.props.isMobile ? <></> : <InfoButton />}

      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(CookiesInformation);
