import React from 'react';
import UniversalInput from '../addressAndDelivery/UniversalInput';
import { Row, Col } from 'react-bootstrap';
import CountrySelect from '../addressAndDelivery/CountrySelect';

export default class InvoiceSettings extends React.Component{
    render(){
        return(
            <>
            <UniversalInput
              placeholder="Name"
              label="Name"
              id="InvoiceName"
              value={this.props.user.invoiceData.name}
              handleInputChange={this.props.handleInvoiceNameChange}
              isError={this.props.hasBeenSubmited && (this.props.user.invoiceData.name == null || this.props.user.invoiceData.name.length < 2)}
              errorMessage='This field cannot be empty'
            />
            <UniversalInput
              placeholder="CompanyName"
              label="Company Name"
              id="InvoiceCompanyName"
              value={this.props.user.invoiceData.company_name}
              handleInputChange={this.props.handleInvoiceCompanyNameChange}
              isError={this.props.hasBeenSubmited && ( this.props.user.invoiceData.company_name ==  null || this.props.user.invoiceData.company_name.length < 2)}
              errorMessage='This field cannot be empty'
            />
            <UniversalInput
              placeholder="Address"
              label="Address"
              id="InvoiceAddress"
              value={this.props.user.invoiceData.address}
              handleInputChange={this.props.handleInvoiceAddressChange}
              isError={this.props.hasBeenSubmited && (this.props.user.invoiceData.address == null || this.props.user.invoiceData.address.length < 2)}
              errorMessage='This field cannot be empty'
            />
            <Row>
              <Col>
                <CountrySelect
                  value={this.props.user.invoiceData.country}
                  handleInputChange={this.props.handleInvoiceCountryChange}
                />
              </Col>
              <Col>
                <UniversalInput
                  placeholder="Postal Code"
                  label="Postal Code"
                  id="InvoicePostalCode"
                  value={this.props.user.invoiceData.post_code}
                  handleInputChange={this.props.handleInvoicePostalChange}
                  isError={this.props.hasBeenSubmited && (this.props.user.invoiceData.post_code == null || this.props.user.invoiceData.post_code.length < 2)}
                  errorMessage='This field cannot be empty'
                />
              </Col>
            </Row>
            <UniversalInput
              placeholder="VAT number"
              label="VAT number"
              id="InvoiceVATnumber"
              value={this.props.user.invoiceData.nip}
              handleInputChange={this.props.handleInvoiceVATChange}
              isError={this.props.hasBeenSubmited && (this.props.user.invoiceData.nip == null || this.props.user.invoiceData.nip.length < 2)}
              errorMessage='This field cannot be empty'
            />{" "}
          </>
        )
    }
}