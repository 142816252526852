import React, { useState } from "react";
import { Form, Row, Col, Image, Container } from "react-bootstrap";
import {Link} from "react-router-dom";
import queryString from 'query-string';
import PasswordInput from "../components/resetPassword/PasswordInput";
import ConfirmButton from "../components/resetPassword/ConfirmButton";
import colors from "../colors/colors";
import frayaLogo from "../img/Fraya-Logo.png";
import logoMobile from "../img/Logo-mobile.png";
import { connect } from "react-redux";
import { isMobileFetched, userFetched } from "../actions";
import Request from "../api/Request";

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPasswordInputValue: "",
      confirmPasswordInputValue: "",
      showPassword: true,
      isMobile: false,
      errorNumber: 1,
      user: {},
    };
  }
  
  componentDidMount(){
      this.setState({
          user:  this.props.user
      })
  }

  handleNewPasswordChange(event) {
    const { value } = event.target;
    this.setState({ newPasswordInputValue: value });
  }

  handleConfirmPasswordChange(event) {
    const { value } = event.target;
    this.setState({ confirmPasswordInputValue: value });
  }

  eyeClick() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  forgotPasswordClick() {
    this.setState({ forgotPassword: !this.state.forgotPassword });
  }

  confirmNewPassword(){
    this.setState({error: false});
    if(this.state.newPasswordInputValue == this.state.confirmPasswordInputValue){
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            password: this.state.newPasswordInputValue,
          },
        }), 
        ()=> {this.props.userFetched(this.state.user);
            this.props.history.push('/');
            Request.saveMyData(this.state.user, (response) => {
                if (response.success) {
                  this.props.userFetched(this.state.user);
                } else {
                  this.setState({errorNumber: 2, error: true });
                }});
        }
        );
    }else{
        this.setState({errorNumber: 1, error: true});
    }
  }

  render() {
    const errorText = this.state.errorNumber == 1 ? 'The values are not the same' : 'Something went wrong, please try again later';
    return (
      <Container style={{ maxWidth: "100vw" }}>
        <Row
          style={
            this.props.isMobile
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colors.blue,
                  height: "100vh",
                  padding: "50px"
                }
              : {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colors.blue,
                  height: "100vh"
                }
          }
        >
          <Col md='4' sm='12' style={this.props.isMobile ? {minHeight: '170px'} : {}}>
            <Image
              src={this.props.isMobile ? logoMobile : frayaLogo}
              style={
                this.props.isMobile
                  ? {
                      position: "fixed",
                      width: "115px",
                      top: "40px",
                      left: "calc(50% - 60px)"
                    }
                  : {
                      position: "fixed",
                      top: "40px",
                      left: "40px"
                    }
              }
            />
          </Col>
          <Col  md='4' sm='12' >
            <Row>
              <Col md="2"></Col>
              <Col>
                  <div style={{ textAlign: "center" }}>
                    <h2
                      style={{
                        color: colors.white,
                        fontSize: "21px",
                        fontFamily: "Lato",
                        fontWeight: "300",
                        textAlign: "left",
                        marginBottom: "30px"
                      }}
                    >
                      Change Password.
                    </h2>
                    <Form onSubmit={this.confirmNewPassword.bind(this)}>
                      <PasswordInput
                        type={this.state.showPassword}
                        password={this.state.newPasswordInputValue}
                        handleInputChange={this.handleNewPasswordChange.bind(this)}
                        eyeClick={this.eyeClick.bind(this)}
                        label="New Password"
                      />
                      <PasswordInput
                        type={this.state.showPassword}
                        password={this.state.confirmPasswordInputValue}
                        handleInputChange={this.handleConfirmPasswordChange.bind(this)}
                        eyeClick={this.eyeClick.bind(this)}
                        label="Confirm new Password"
                      />
                      <ConfirmButton onClick={this.confirmNewPassword.bind(this)} />
                      {this.state.error && <p style={{color : 'red'}}>{errorText}</p>}
                    </Form>
                  </div>
              </Col>
              <Col md="2"></Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    user: state.user,
  };
};
const mapDispatchToProps = { isMobileFetched, userFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);