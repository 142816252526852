import React from "react";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import { PencilSquare } from 'react-bootstrap-icons';
import colors from "../../colors/colors";

export default class UniversalInput extends React.Component {
  render() {
    return (
      <Row>
        <Col>
          <div className="form-label-group" >
            <Form.Control
              required
              id={this.props.id}
              placeholder={this.props.placeholder}
              type={this.props.type}
              value={this.props.value}
              onChange={this.props.handleInputChange}
            />
            <Form.Label for={this.props.id}>{this.props.label}</Form.Label>
            <InputGroup.Append>
              <InputGroup.Text  style={{
                backgroundColor: colors.white, 
                borderColor: colors.blue,
                borderRadius: '0px 5px 5px 0px',
                borderLeftWidth: '0',
                padding: '7px',
                }}><PencilSquare color={colors.blue} size={8}/></InputGroup.Text>
            </InputGroup.Append>
          </div>
          {this.props.isError? <p style={{
            fontSize: '10px',
             color: 'red', 
             width: '100%', 
             marginBottom: '4px',
             marginTop: '-16px',
             marginLeft: '10px',
             }}>{this.props.errorMessage}</p> : <></>}
        </Col>
      </Row>
    );
  }
}

