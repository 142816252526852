import React from "react";
import { Form } from "react-bootstrap";
import colors from "../../colors/colors";

export default class LoginInput extends React.Component {
  render() {
    return (
      <Form.Group className='loginInput' controlId="loginEmail">
        <Form.Label style={{
          color: colors.white,
          fontSize: '16px',
          fontFamily: 'Lato',
          fontWeight: '300',
          textAlign: 'left', }}>E-mail address</Form.Label>
        <Form.Control type="email" value={this.props.login} onChange={this.props.handleInputChange} />
      </Form.Group>
    );
  }
}

