import React from 'react';


export default class ListingPage extends React.Component{
    rawTotal(){
        let total = 0;
        const products = this.props.cart;
        for(let i=0; i < products.length; i++){
            total = total + products[i].price * products[i].quantity;
        }
    
        return total;
      }
    
      discountPercentage(){
        let total = this.rawTotal();
        let currentDiscount = 0;
        if(this.props.user.discount){
        this.props.user.discount.forEach(discount => {
          if(currentDiscount < discount.percentage && total >= discount.price){
            currentDiscount = discount.percentage;
          }
        });
      }
        return currentDiscount;
      }
      
      priceSum(){
        const total = this.rawTotal();
        const discount = this.discountPercentage();
    
        if(discount){
          return total * ((100 - discount) * 0.01);
        }
    
        return  total;
      }
    
      topInfoData(product, quantity){
        this.setState({ addedQuantity: quantity, addedProductName: product.name, })
      }
}
