import React from 'react';
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from '../../colors/colors';
import SearchScreen from '../general/SearchScreen';
import { connect } from "react-redux";
import { isMobileFetched, currentCategoryFetched, cartFetched, searchValueFetched } from "../../actions";
import Request from '../../api/Request';
import  { ReactComponent as Sygnet } from '../../svg/sygnet.svg'
import  { ReactComponent as Cart } from '../../svg/cart.svg'
import  { ReactComponent as Search } from '../../svg/search.svg'
import  { ReactComponent as User } from '../../svg/user.svg'
import BrandList from './BrandList';

class ShopHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            brands: [],
        };
    }

    componentDidMount() {
        Request.getCategories(response => {
            if (response.success) {
                this.setState({ brands: response.categories });
            }
        });
    }

    hideSearchScreen() {
        this.setState({ showSearch: false });
    }

    showSearchScreen() {
        this.setState({ showSearch: true });
    }

    setCategory(id) {
        this.props.currentCategoryFetched(id);
        this.props.searchValueFetched('');
    }

    
  setShowBrandListValue(){
    if(this.state.showBrandList){
      this.setState({showBrandList: false});
    }else{
      this.setState({showBrandList: true});
    }
  }

    render() {
        const icon = {
            width: '25px',
            marginTop: '25px'
        }

        const menuItem = {
            color: colors.blue,
            fontSize: "24px",
            fontFamily: "Lato",
            fontWeight: "300",
            textAlign: "left",
            cursor: 'pointer',
        }

        const menuItemActive = {
            color: colors.blue,
            fontSize: "24px",
            fontFamily: "Lato",
            fontWeight: "600",
            textAlign: "left",
            cursor: 'pointer',
        }

        const cartNumber = {
            border: 'solid #357490 2px',
            fontSize: '10px',
            borderRadius:'28px',
            textAlign: 'center',
            letterSpacing: 0,
            fontWeight: 'bold',
            width: '20px',
            position: 'relative',
            top: '-30px',
            left: '15px',
            background: colors.white,
            color: colors.blue,
          }

        return (
            <Row>
                <Col md='2' style={this.props.isMobile ? { textAlign: 'center' } : {}}><Link to='/'><Sygnet style={this.props.isMobile ? { marginTop: '10px', width: '30px' } : { marginTop: '10px' }} /></Link></Col>
                <Col style={this.props.isMobile ? { display: 'none' } : { marginTop: '25px' }}>
                    <Row style={{}}>
                        {this.state.brands.filter(brand => brand.favourite == 1).map(brand => <Col key={brand.id}>
                            <Link style={{ textDecoration: 'none' }} to='/products'><h3 onClick={() => this.setCategory(brand.id)} style={this.props.currentCategory == brand.id ? menuItemActive : menuItem }>{brand.name}</h3></Link>
                        </Col>)}
                        <Col>
                            <div style={{paddingBottom: '10px'}} onMouseEnter={this.setShowBrandListValue.bind(this)} onMouseLeave={this.setShowBrandListValue.bind(this)}>
                                <h3 style={menuItem}>More brands</h3>
                                {this.state.showBrandList ?
                                <BrandList onMouseEnter={this.setShowBrandListValue.bind(this)} onMouseLeave={this.setShowBrandListValue.bind(this)} brands={this.state.brands} currentCategory={this.props.currentCategory}/>
                                    : <></>}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md='2' style={this.props.isMobile ? { display: 'none' } : {minWidth: '210px'}}>
                    <Row style={{ marginLeft: '30px' }}>
                        <Col><Search onClick={this.showSearchScreen.bind(this)} style={icon} /></Col>
                        <Col><Link className='cartNumber' to='/cart'><Cart style={icon} />{this.props.cartLength > 0 ? <p style={cartNumber}>{this.props.cart.length}</p> : <></>}</Link></Col>
                        <Col><Link to='/acount'><User style={icon} /></Link></Col>
                    </Row>
                </Col>
                <SearchScreen
                    showSearch={this.state.showSearch}
                    hide={this.hideSearchScreen.bind(this)} />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.isMobile, // (1)
        currentCategory: state.currentCategory,
        cart: state.cart,
    };
};
const mapDispatchToProps = { isMobileFetched, currentCategoryFetched, cartFetched, searchValueFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ShopHeader);