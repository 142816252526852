import React from 'react';
import { Button } from "react-bootstrap";
import colors from "../../colors/colors";
import { CheckCircle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Price from '../general/Price';

export default class MobileInfo extends React.Component{
    render(){

        
    const showMobileInfoClass = this.props.class
    ? "mobileInfo"
    : "mobileInfo  mobileInfoHidden";


        return(
            <div
          className={showMobileInfoClass}
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(53, 116, 144, 0.9)",
            top: "0",
            left: "0",
            transition: '1s',
            zIndex: '1',
          }}
        >
          <div
            className={showMobileInfoClass}
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              top: "0",
              left: "0",
              textAlign: "center",
              transition: '1s'
            }}
          >
            <CheckCircle
              size={70}
              color={colors.white}
              style={{ position: "relative", top: "140px" }}
            />
            <h3
              style={{
                fontSize: "18px",
                fontFamily: "lato",
                color: colors.white,
                position: "relative",
                top: "200px",
                width: "65%",
                margin: " 0 auto",
              }}
            >
              You have added {this.props.product} {this.props.historicalOrder ? '' : 'in quantity of'} {this.props.quantity} to cart.
              <br /> Your cart value is <Price price={this.props.cartSum} />.{" "}
            </h3>

            <Button
              onClick={this.props.hideMobileInfo}
              style={{
                position: "relative",
                top: "300px",
                color: colors.white,
                borderColor: colors.white,
                border: "solid 3px",
                padding: "3px 30px",
                borderRadius: "3px",
                fontFamily: "lato",
                fontWeight: "600",
                width: "210px",
              }}
              variant="outline-info"
            >
              Continue shopping
            </Button>
            <h3
              style={{
                fontSize: "18px",
                fontFamily: "lato",
                color: colors.white,
                position: "relative",
                top: "325px",
                width: "65%",
                margin: " 0 auto",
              }}
            >
              or
            </h3>
            <Link to='/cart'>
            <Button
              style={{
                position: "relative",
                top: "345px",
                color: colors.white,
                borderColor: colors.white,
                border: "solid 3px",
                padding: "3px 30px",
                borderRadius: "3px",
                fontFamily: "lato",
                fontWeight: "600",
                width: "210px",
              }}
              variant="outline-info"
            >
              Check out
            </Button></Link>
          </div>
        </div>
        )
    }
}