import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import CheckoutHeader from "../components/headers/CheckoutHeader";
import UniversalInput from "../components/addressAndDelivery/UniversalInput";
import InfoButton from "../components/general/InfoButton";
import CountrySelect from "../components/addressAndDelivery/CountrySelect";
import MobileMenu from "../components/general/MobileMenu";
import { connect } from "react-redux";
import { isMobileFetched, deliveryInfoFetched, cartFetched } from "../actions";
import { ReactComponent as ArrowLeft } from '../svg/arrowLeft.svg';
import InvoiceSettings from "../components/myAcount/InvoiceSettings";
import ShippingSettings from "../components/myAcount/ShippingSettings";
import { withRouter } from "react-router-dom";
import countryCodes from "../components/addressAndDelivery/countryCodes";

class DeliveryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      checkoutPage: true,
      nameInputValue: "",
      lastNameInputValue: "",
      emailInputValue: "",
      countryNumberInputValue: "",
      phoneInputValue: "",
      adressInputValue: "",
      countryInputValue: "",
      postalInputValue: "",
      nipInputValue: '',
      companyNameInputValue: "",
      deliveryValue: 'standard',
      headerID: 2,
    };
  }

  componentDidMount() {
    if (this.props.user.name) {
      this.setState({
        nameInputValue: this.props.user.name,
        lastNameInputValue: this.props.user.lastName,
        emailInputValue: this.props.user.email,
        countryNumberInputValue: this.props.user.countryNumber,
        phoneInputValue: this.props.user.phone,
        adressInputValue: this.props.user.adress,
        countryInputValue: this.props.user.country,
        postalInputValue: this.props.user.postal,
        companyNameInputValue: this.props.user.companyName,
        nipInputValue: this.props.user.nip,
        useDifferentInvoiceData: this.props.user.useDifferentInvoiceData,
        useDifferentShippingData: this.props.user.useDifferentShipingData,
        differentData: { invoiceData: this.props.user.invoiceData, shipping: this.props.user.shipping }
      });
    }

    if (Object.keys(this.props.cart).length === 0) {
      this.props.history.push('/');
    }
  }

  handleNameChange(event) {
    const { value } = event.target;
    this.setState({ nameInputValue: value });
  }

  handleLastNameChange(event) {
    const { value } = event.target;
    this.setState({ lastNameInputValue: value });
  }

  handleEmailChange(event) {
    const { value } = event.target;
    this.setState({ emailInputValue: value });
  }

  handleCountryNumberChange(event) {
    const { value } = event.target;
    this.setState({ countryNumberInputValue: value });
  }

  handlePhoneChange(event) {
    const { value } = event.target;
    this.setState({ phoneInputValue: value });
  }

  handleAdressChange(event) {
    const { value } = event.target;
    this.setState({ adressInputValue: value });
  }
  handleCountryChange(event) {
    const { value } = event.target;

    if (this.state.countryNumberInputValue == '' || this.state.countryNumberInputValue == null) {
      this.setState({ countryInputValue: value, countryNumberInputValue: '+' + countryCodes[value] });
    } else {
      this.setState({ countryInputValue: value });
    }

  }
  handlePostalCodeChange(event) {
    const { value } = event.target;
    this.setState({ postalInputValue: value });
  }

  handleDeliveryChange(event) {
    const { value } = event.target;
    this.setState({ deliveryValue: value });
  }

  handleCompanyNameChange(event) {
    const { value } = event.target;
    this.setState({ companyNameInputValue: value });
  }

  handleNipChange(event) {
    const { value } = event.target;
    this.setState({ nipInputValue: value });
  }


  setDeliveryInfo() {
    if (!this.validate()) {
      this.setState({ hasBeenSubmited: true });
      return;
    }

    const deliveryInfo = {
      name: this.state.nameInputValue,
      lastName: this.state.lastNameInputValue,
      email: this.state.emailInputValue,
      countryNumber: this.state.countryNumberInputValue,
      phone: this.state.phoneInputValue,
      adress: this.state.adressInputValue,
      country: this.state.countryInputValue,
      postal: this.state.postalInputValue,
      delivery: this.state.deliveryValue,
      companyName: this.state.companyNameInputValue,
      nip: this.state.nipInputValue,
      useDifferentInvoiceData: this.state.useDifferentInvoiceData,
      useDifferentShippingData: this.state.useShippingInvoiceData,
      differentData: this.state.differentData,
      currency: this.props.user.currency,
    }

    this.props.deliveryInfoFetched(deliveryInfo);

    this.props.history.push('/payment');
  }

  handleInvoiceCheckChange() {
    if (this.state.useDifferentInvoiceData) {
      this.setState({ useDifferentInvoiceData: false });
    } else {
      this.setState({ useDifferentInvoiceData: true });
    }
  }

  handleShippingCheckChange() {
    if (this.state.useDifferentShippingData) {
      this.setState({ useDifferentShippingData: false });
    } else {
      this.setState({ useDifferentShippingData: true });
    }
  }

  handleInvoiceCompanyNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        invoiceData: {
          ...prevState.differentData.invoiceData,
          company_name: value,
        },
      },
    }));
  }

  handleInvoiceAddressChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        invoiceData: {
          ...prevState.differentData.invoiceData,
          address: value,
        },
      },
    }));
  }

  handleInvoiceNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        invoiceData: {
          ...prevState.differentData.invoiceData,
          name: value,
        },
      },
    }));
  }

  handleInvoiceCountryChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        invoiceData: {
          ...prevState.differentData.invoiceData,
          country: value,
        },
      },
    }));
  }

  handleInvoicePostalChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        invoiceData: {
          ...prevState.differentData.invoiceData,
          post_code: value,
        },
      },
    }));
  }

  handleInvoiceVATChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        invoiceData: {
          ...prevState.differentData.invoiceData,
          nip: value,
        },
      },
    }));
  }

  handleShippingCompanyNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        shipping: {
          ...prevState.differentData.shipping,
          company_name: value,
        },
      },
    }));
  }

  handleShippingAddressChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        shipping: {
          ...prevState.differentData.shipping,
          address: value,
        },
      },
    }));
  }

  handleShippingNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        shipping: {
          ...prevState.differentData.shipping,
          name: value,
        },
      },
    }));
  }

  handleShippingCountryChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        shipping: {
          ...prevState.differentData.shipping,
          country: value,
        },
      },
    }));
  }

  handleShippingPostalChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      differentData: {
        ...prevState.differentData,
        shipping: {
          ...prevState.differentData.shipping,
          post_code: value,
        },
      },
    }));
  }

  validate() {
    return (this.state.nameInputValue != null && this.state.nameInputValue.length > 1) &&
      (this.state.lastNameInputValue != null && this.state.lastNameInputValue.length > 1) &&
      (this.state.companyNameInputValue != null && this.state.companyNameInputValue.length > 1) &&
      (this.state.emailInputValue != null && this.state.emailInputValue.length > 1) &&
      (this.state.countryNumberInputValue != null && this.state.countryNumberInputValue.length > 1) &&
      (this.state.phoneInputValue != null && this.state.phoneInputValue.length > 1) &&
      (this.state.postalInputValue != null && this.state.postalInputValue.length > 1) &&
      (this.state.adressInputValue != null && this.state.adressInputValue.length > 1) &&
      (this.state.nipInputValue != null && this.state.nipInputValue.length > 1) &&

      (!this.state.useDifferentInvoiceData ||
        (this.state.differentData.invoiceData.name != null && this.state.differentData.invoiceData.name.length > 1) &&
        (this.state.differentData.invoiceData.company_name != null && this.state.differentData.invoiceData.company_name.length > 1) &&
        (this.state.differentData.invoiceData.address != null && this.state.differentData.invoiceData.address.length > 1) &&
        (this.state.differentData.invoiceData.post_code != null && this.state.differentData.invoiceData.post_code.length > 1) &&
        (this.state.differentData.invoiceData.nip != null && this.state.differentData.invoiceData.nip.toString().length > 1)
      ) &&

      (!this.state.useDifferentShipingData ||
        (this.state.differentData.shipping.name != null && this.state.differentData.shipping.name.length > 1) &&
        (this.state.differentData.shipping.company_name != null && this.state.differentData.shipping.company_name.length > 1) &&
        (this.state.differentData.shipping.address != null && this.state.differentData.shipping.address.length > 1) &&
        (this.state.differentData.shipping.post_code != null && this.state.differentData.shipping.post_code.length > 1)
      )
  }

  render() {
    const title = {
      color: colors.blue,
      fontSize: "24px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
      marginTop: '30px',
      marginBottom: '30px',
    };

    const button = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 3px",
      padding: "3px 30px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      position: 'absolute',
      top: this.props.isMobile ? 'none' : '607px',
      marginBottom: this.props.isMobile ? '50px' : '0px',
      left: 'calc((100vw - 219px) / 2)',
    };

    const buttonMobile = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 1px",
      padding: "0px 20px",
      borderRadius: "1px",
      fontFamily: "lato",
      fontWeight: "600",
      fontSize: "14px",
      margiTop: "11px"
    };

    return (
      <Container style={{ maxWidth: "100vw" }}>
        <CheckoutHeader headerID={this.state.headerID} />
        <Row style={this.props.isMobile ? {} : { margin: "0 8%" }}>
          <Col md='4' xs='12'>
            <h3 style={title}>Address</h3>
            <Form>
              <UniversalInput
                placeholder="Name"
                label="Name"
                id="Name"
                value={this.state.nameInputValue}
                handleInputChange={this.handleNameChange.bind(this)}
                isError={this.state.hasBeenSubmited && (this.state.nameInputValue == null || this.state.nameInputValue.length < 2)}
                errorMessage='This field cannot be empty'
              />
              <UniversalInput
                placeholder="Last Name"
                label="Last Name"
                id="LastName"
                value={this.state.lastNameInputValue}
                handleInputChange={this.handleLastNameChange.bind(this)}
                isError={this.state.hasBeenSubmited && (this.state.lastNameInputValue == null || this.state.lastNameInputValue.length < 2)}
                errorMessage='This field cannot be empty'
              />
              <UniversalInput
                placeholder="E-mail"
                label="E-mail"
                id="E-mail"
                value={this.state.emailInputValue}
                handleInputChange={this.handleEmailChange.bind(this)}
                isError={this.state.hasBeenSubmited && (this.state.emailInputValue == null || this.state.emailInputValue.length < 2)}
                errorMessage='This field cannot be empty'
              />
              <UniversalInput
                placeholder="Company Name"
                label="Company Name"
                id="CompanyName"
                value={this.state.companyNameInputValue}
                handleInputChange={this.handleCompanyNameChange.bind(this)}
                isError={this.state.hasBeenSubmited && (this.state.companyNameInputValue == null || this.state.companyNameInputValue.length < 2)}
                errorMessage='This field cannot be empty'
              />
              <UniversalInput
                placeholder="Address"
                label="Address"
                id="Address"
                value={this.state.adressInputValue}
                handleInputChange={this.handleAdressChange.bind(this)}
                isError={this.state.hasBeenSubmited && (this.state.adressInputValue == null || this.state.adressInputValue.length < 2)}
                errorMessage='This field cannot be empty'
              />
              <Row>
                <Col>
                  <CountrySelect value={this.state.countryInputValue}
                    handleInputChange={this.handleCountryChange.bind(this)} />
                </Col>
                <Col>
                  <UniversalInput
                    placeholder="Postal Code"
                    label="Postal Code"
                    id="PostalCode"
                    value={this.state.postalInputValue}
                    handleInputChange={this.handlePostalCodeChange.bind(this)}
                    isError={this.state.hasBeenSubmited && (this.state.postalInputValue == null || this.state.postalInputValue.length < 2)}
                    errorMessage='This field cannot be empty'
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={3} style={{ paddingRight: "0px" }}>
                  <UniversalInput
                    placeholder=""
                    label=""
                    id="CountryNumber"
                    value={this.state.countryNumberInputValue}
                    handleInputChange={this.handleCountryNumberChange.bind(this)}
                    isError={this.state.hasBeenSubmited && (this.state.countryNumberInputValue == null || this.state.countryNumberInputValue.length < 2)}
                    errorMessage='This field cannot be empty'
                  />
                </Col>
                <Col>
                  <UniversalInput
                    placeholder="Phone number"
                    label="Phone number"
                    id="PhoneNumber"
                    value={this.state.phoneInputValue}
                    handleInputChange={this.handlePhoneChange.bind(this)}
                    isError={this.state.hasBeenSubmited && (this.state.phoneInputValue == null || this.state.phoneInputValue.length < 2)}
                    errorMessage='This field cannot be empty'
                  />
                </Col>
              </Row>
              <UniversalInput
                placeholder="VAT number"
                label="VAT number"
                id="VATnumber"
                value={this.state.nipInputValue}
                handleInputChange={this.handleNipChange.bind(this)}
                isError={this.state.hasBeenSubmited && (this.state.nipInputValue == null || this.state.nipInputValue.length < 2)}
                errorMessage='This field cannot be empty'
              />
              <div className="InvoiceSettings" style={{ marginTop: '-25px' }}>
                <h3 style={title}>Invoicing details</h3>
                <Form.Check
                  checked={this.state.useDifferentInvoiceData}
                  type={"checkbox"}
                  label={`My Invoice Settings don't match my main settings`}
                  onChange={this.handleInvoiceCheckChange.bind(this)}
                  style={{ fontSize: '14px', marginBottom: '30px', marginTop: '-15px' }}
                />
                {this.state.useDifferentInvoiceData ? (
                  <InvoiceSettings
                    handleInvoiceNameChange={this.handleInvoiceNameChange.bind(this)}
                    handleInvoiceCompanyNameChange={this.handleInvoiceCompanyNameChange.bind(this)}
                    handleInvoiceAddressChange={this.handleInvoiceAddressChange.bind(this)}
                    handleInvoiceCountryChange={this.handleInvoiceCountryChange.bind(this)}
                    handleInvoicePostalChange={this.handleInvoicePostalChange.bind(this)}
                    handleInvoiceVATChange={this.handleInvoiceVATChange.bind(this)}
                    user={this.state.differentData}
                    hasBeenSubmited={this.state.hasBeenSubmited} />
                ) : (
                    <></>
                  )}
              </div>
              <div className="ShippingSettings" style={{ marginTop: '-10px' }}>
                <h3 style={title}>Shipping details</h3>
                <Form.Check
                  checked={this.state.useDifferentShippingData}
                  type={"checkbox"}
                  label={`My Shipping Settings don't match my main settings`}
                  onChange={this.handleShippingCheckChange.bind(this)}
                  style={{ fontSize: '14px', marginBottom: '30px', marginTop: '-15px' }}
                />
                {this.state.useDifferentShippingData ?
                  <ShippingSettings
                    handleShippingNameChange={this.handleShippingNameChange.bind(this)}
                    handleShippingCompanyNameChange={this.handleShippingCompanyNameChange.bind(this)}
                    handleShippingAddressChange={this.handleShippingAddressChange.bind(this)}
                    handleShippingCountryChange={this.handleShippingCountryChange.bind(this)}
                    handleShippingPostalChange={this.handleShippingPostalChange.bind(this)}
                    user={this.state.differentData}
                    hasBeenSubmited={this.state.hasBeenSubmited} />
                  : <></>}
              </div>
            </Form>
          </Col>
          <Col md='4' xs='12'>
            <h3 style={title}>Delivery</h3>
            <div className="form-label-group deliverySelect-group">
              <Form.Control
                as="select"
                value={this.state.deliveryValue}
                onChange={this.handleDeliveryChange.bind(this)}
              >
                <option value='standard'>Standard delivery</option>
                <option value='expres'>Express delivery</option>
                <option value='premium'>Premium delivery</option>
              </Form.Control>
              <Form.Label className="deliverySelect">Delivery</Form.Label>
            </div>
          </Col>
          {/* <Col md='4' xs='12'>
            <h3 style={title}>Company</h3>
            <UniversalInput
                placeholder="CompanyName"
                label="Company Name"
                value={this.state.companyNameInputValue}
                handleInputChange={this.handleCompanyNameChange.bind(this)}
              />
              <UniversalInput
                placeholder="NIP"
                label="NIP"
                value={this.state.nipInputValue}
                handleInputChange={this.handleNipChange.bind(this)}
              />
          </Col> */}
        </Row>
        <Row style={this.props.isMobile ? { marginBottom: '60px' } : {}}>
          {/* <Col style={{ textAlign: "center", marginBottom: '50px' }}> */}
          <Button
            type="submit"
            onClick={this.setDeliveryInfo.bind(this)}
            style={this.props.mobile ? buttonMobile : button}
            variant="outline-info"
          >
            Confirm your address
            </Button>
          {this.state.hasBeenSubmited ? <p style={ this.props.isMobile ? { color: 'red', position: 'relative', top: '34px', width: '100vw', textAlign: 'center' } : 
          { color: 'red', position: 'absolute', top: '642px', width: '100vw', textAlign: 'center' }}>Please check your data</p> : <></>}
          {/* </Col> */}
        </Row>
        {this.props.isMobile ? <></> : <InfoButton />}
        <Row style={this.props.isMobile ? { display: 'none' } : {}}>
          <Col>
            <Link to='/cart'>
              <ArrowLeft style={{ position: 'fixed', top: '120px' }} />
            </Link>
          </Col>
        </Row>
        {this.props.isMobile ? <MobileMenu checkoutPage={this.state.checkoutPage} /> : <></>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    user: state.user,
    deliveryInfo: state.deliveryInfo,
    cart: state.cart,
  };
};
const mapDispatchToProps = { isMobileFetched, deliveryInfoFetched, cartFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeliveryPage));
