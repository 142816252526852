import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import ProductInCart from "../components/cart/ProductInCart";
import EmptyHeader from "../components/headers/EmptyHeader";
import InfoButton from "../components/general/InfoButton";
import MobileMenu from "../components/general/MobileMenu";
import products from "../components/general/products";
import { connect } from "react-redux";
import {
  isMobileFetched,
  historicalOrderFetched,
  cartFetched,
  userFetched,
} from "../actions";
import ListingPage from "./ListingPage";
import Request from "../api/Request";
import Price from "../components/general/Price";
import MobileInfo from "../components/productList/mobileInfo";
import  { ReactComponent as ArrowLeft } from '../svg/arrowLeft.svg';

class SingleOrderPage extends ListingPage {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: true,
      showMobileInfo: false,
      historicalOrderPage: true,
      orderName: "Order Name",
      acountPage: true,
      order: {},
      products: [],
    };
  }

  componentDidMount() {
    Request.getProducts((response) => {
      if (response.success) {
        let products = response.products;
        let filteredProducts = [];
        if (this.props.historicalOrder && this.props.historicalOrder.number) {
          this.props.historicalOrder.products.forEach((historicalProduct) => {
            products.forEach((product) => {
              if (historicalProduct.id == product.id) {
                let newProduct = { ...product };
                newProduct.quantity = historicalProduct.quantity;
                filteredProducts.push(newProduct);
              }
            });
          });
          this.setState({ products: filteredProducts });
        }
      }
    });

    if(Object.keys(this.props.historicalOrder).length === 0){
      this.props.history.push('/')
    }
  }

  handleQuantityChange(event, id) {
    const { value } = event.target;
    this.setState((state) => {
      state.products[id].quantity = value;
      return state;
    });
  }

  quantityPlus(id) {
    this.setState((state) => {
      state.products[id].quantity = parseInt(state.products[id].quantity) + 5;
      return state;
    });
  }

  quantityMinus(id) {
    this.setState((state) => {
      state.products[id].quantity -= 5;
      return state;
    });
  }

  OrderPriceSum() {
    let total = 0;
    let discount = this.discountPercentage();
    const products = this.state.products;
    for (let i = -0; i < products.length; i++) {
      total = total + products[i].price * products[i].quantity;
    }

    return total * ((100 - discount) * 0.01);
  }

  removeProduct(id) {
    const products = this.state.products;
    products.splice(id, 1);

    this.setState({ products: products });
  }

  hideTopInfo() {
    this.setState({
      timeOut: setTimeout(() => this.setState({ showTopInfo: false }), 3000),
    });
  }

  stopHideTopInfo() {
    clearTimeout(this.state.timeOut);
    this.setState({ showTopInfo: true });
  }

  showMobileInfo() {
    this.setState({ showMobileInfo: true });
  }

  hideMobileInfo() {
    this.setState({ showMobileInfo: false });
  }

  addToCart() {
    this.state.products.forEach((product) => {
      const cart = this.props.cart;

      let found = false;

      cart.forEach((element, id) => {
        if (element.id == product.id) {
          found = true;
          cart[id].quantity += product.quantity;
        }
      });

      if (!found) {
        cart.push({ ...product });
      }
      this.props.cartFetched(cart);
    });
  }

  render() {
    const columnHeader = {
      fontSize: "16px",
      fontFamily: "Lato",
      fontWeight: "300",
    };

    const title = {
      color: colors.blue,
      fontSize: "24px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
    };

    const button = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 3px",
      padding: "3px 30px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      width: "210px",
    };

    const buttonMobile = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 1px",
      padding: "0px 20px",
      borderRadius: "1px",
      fontFamily: "lato",
      fontWeight: "600",
      fontSize: "14px",
      margiTop: "11px",
    };

    const showTopInfoClass = this.state.showTopInfo
      ? "topInfo"
      : "topInfo  topInfoHidden";

    return (
      <Container style={{ maxWidth: "100vw" }}>
        <EmptyHeader cartLength={this.props.cart.length} orderPage={true} acountPage={this.state.acountPage} />
        <Row>
          <Col
            style={
              this.props.isMobile ? { margin: "10px 0 0" } : { margin: "0 8%" }
            }
          >
            <h3 style={title}>{this.props.historicalOrder.number}</h3>
          </Col>
        </Row>
        <Row
          style={
            this.props.isMobile
              ? { margin: "0 auto 50px "}
              : { margin: "0 8% 50px 8%" }
          }
        >
          <Col md="6" xs={0}></Col>
          <Col md="2" xs={4} style={{padding: '0'}}>
            <h4
              style={{
                fontWeight: "600",
                fontSize: "16px",
                fontFamily: "Lato",
              }}
            >
              Old-discount
            </h4>
          </Col>
          <Col md="1" xs={2} style={this.props.isMobile ? { padding: '0', fontWeight: "600" } : {fontWeight: "600"}}>
            {this.props.historicalOrder.discount}%
          </Col>
          <Col md="2" xs="4">
            <h4
              style={{
                fontWeight: "600",
                fontSize: "16px",
                fontFamily: "Lato",
              }}
            >
              Old-total
            </h4>
          </Col>
          <Col md="1" xs="2" style={{ fontWeight: "600" }}>
            <Price price={this.props.historicalOrder.total} />
          </Col>
        </Row>
        <Row
          style={
            this.props.isMobile
              ? { display: " none" }
              : { margin: "0 8% 20px 8%" }
          }
        >
          <Col md="6">
            <h4 style={columnHeader}></h4>
          </Col>
          <Col md="3">
            <h4 style={columnHeader}>Your Order</h4>
          </Col>
          <Col md="2">
            <h4 style={columnHeader}>Your Discount</h4>
          </Col>
          <Col md="1">
            <h4 style={columnHeader}>Price</h4>
          </Col>
        </Row>

        {this.state.products.map((product, id) => {
          return (
            <ProductInCart
              number={id}
              key={id}
              name={product.name}
              image={product.small_image}
              discount={this.discountPercentage()}
              price={product.price}
              productQuantity={product.quantity}
              handleInputChange={this.handleQuantityChange.bind(this)}
              productId={id}
              quantityPlus={this.quantityPlus.bind(this)}
              quantityMinus={this.quantityMinus.bind(this)}
              mobile={this.props.isMobile}
              removeProduct={() => {
                this.removeProduct(id);
              }}
            />
          );
        })}
        <Row
          style={
            this.props.isMobile
              ? { margin: "0 auto 50px ", width: "220px" }
              : { margin: "0 8% 50px 8%" }
          }
        >
          <Col md="9"></Col>
          <Col md="2" xs="6">
            <h4
              style={{
                fontWeight: "600",
                fontSize: "16px",
                fontFamily: "Lato",
              }}
            >
              Sub-total
            </h4>
          </Col>
          <Col md="1" xs="6" style={{ fontWeight: "600" }}>
            <Price price={this.OrderPriceSum()} />
          </Col>
        </Row>
        <Row style={{ margin: "0 8%" }}>
          <Col style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                this.stopHideTopInfo();
                this.hideTopInfo();
                this.showMobileInfo();
                this.addToCart();
              }}
              style={this.props.mobile ? buttonMobile : button}
              variant="outline-info"
            >
              REPEAT ORDER
            </Button>
          </Col>
        </Row>
        {this.props.isMobile ? <></> : <InfoButton />}
        {this.props.isMobile ? (
          <MobileInfo
            class={this.state.showMobileInfo}
            hideMobileInfo={this.hideMobileInfo.bind(this)}
            product={this.props.historicalOrder.number}
            quantity={this.state.addedQuantity}
            cartSum={this.priceSum()}
            historicalOrder={this.state.historicalOrderPage}
          />
        ) : (
        <div
          className={showTopInfoClass}
          style={{
            position: "fixed",
            top: "60px",
            backgroundColor: colors.blue,
            padding: "15px",
            width: "85.5%",
            textAlign: "center",
            borderRadius: "5px",
            left: "9.5%",
            transition: "1s",
          }}
        >
          <h3
            style={{
              fontSize: "16px",
              fontFamily: "lato",
              color: colors.white,
            }}
          >
            You have added {this.props.historicalOrder.number} to cart. Your
            cart value is <Price price={this.priceSum()} />.{" "}
          </h3>
        </div> )}
        <Row style={this.props.isMobile ? { display: "none" } : {}}>
          <Col>
            <Link to="/acount">
              <ArrowLeft
                style={{ position: "fixed", top: "120px" }}
              />
            </Link>
          </Col>
        </Row>
        {/* {this.props.isMobile ? (<MobileMenu userPage={this.state.acountPage} />) : (<></>)} */}
        {this.props.isMobile ? <div>{this.state.showMobileInfo ? <></> : <MobileMenu userPage={this.state.acountPage}/> }</div> : <></>}

      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    historicalOrder: state.historicalOrder,
    cart: state.cart,
    user: state.user,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  historicalOrderFetched,
  cartFetched,
  userFetched,
}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(SingleOrderPage);
