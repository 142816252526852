import React from "react";
import { Link } from "react-router-dom";
import colors from '../../colors/colors';
import { connect } from "react-redux";
import { isMobileFetched, currentCategoryFetched, cartFetched, searchValueFetched } from "../../actions";

class BrandList extends React.Component {
  render() {

    const menuItem = {
        color: colors.white,
        fontSize: '18px',
        fontFamily: "Lato",
        textAlign: "left",
        cursor: 'pointer',
        lineHeight: '27px'
      }

    const menuItemActive = {
        color: colors.white,
        fontSize: '18px',
        fontFamily: "Lato",
        textAlign: "left",
        cursor: 'pointer',
        lineHeight: '27px',
        fontWeight: '600',
      }
  
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,15, 0.4)",
          width: "250px",
          maxHeight: "90vh",
          position: "absolute",
          left: '-41px',
          top: "45px",
          borderRadius: "7px",
          overflowY: 'scroll',
          zIndex: '9',
          paddingTop: '15px',
          paddingLeft: '20px',
          backdropFilter: 'blur(6px)',
        }}
      >
        {this.props.brands.filter(brand => brand.favourite == 0).map((brand) => (
            <Link key={brand.id} style={{ textDecoration: "none" }} to="/products">
              <h3
                className="ribbonMenuItem"
                onClick={() => {
                  this.props.searchValueFetched("");
                  this.props.currentCategoryFetched(brand.id);
                }}
                style={this.props.currentCategory == brand.id ? menuItemActive : menuItem }
              >
                {brand.name}
              </h3>
            </Link>
        ))}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    currentCategory: state.currentCategory,
    cart: state.cart,
  };
};
const mapDispatchToProps = { isMobileFetched, currentCategoryFetched, cartFetched, searchValueFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(BrandList);
