import { combineReducers } from "redux";
import {isMobile} from "./isMobile";
import {cart} from "./cart";
import {user} from "./user";
import {currentCategory} from "./currentCategory";
import {searchValue} from "./searchValue";
import {historicalOrder} from "./historicalOrder";
import {deliveryInfo} from "./deliveryInfo";
import {lastOrderId} from "./lastOrderId";

export default combineReducers ({
    isMobile,
    cart,
    user,
    currentCategory,
    searchValue,
    historicalOrder,
    deliveryInfo,
    lastOrderId,
});