import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import { X } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { searchValueFetched, cartFetched } from "../../actions";
import background from "../../img/background.png";
//svg
import { ReactComponent as Sygnet } from "../../svg/sygnetWhite.svg";
import { ReactComponent as Cart } from "../../svg/cartWhite.svg";
import { ReactComponent as Search } from "../../svg/searchWhite.svg";
import { ReactComponent as User } from "../../svg/userWhite.svg";
import { withRouter } from "react-router-dom";
import Request from "../../api/Request";

class SearchScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteIcon: false,
      showList: false,
      alwaysWhiteIcon: false,
      searchInputValue: "",
      hints: [],
    };
  }

  hideMobileList() {
    this.setState({ showList: false });
  }

  showHideMobileList() {
    if (this.state.showList) {
      this.setState({ showList: false, whiteIcon: false });
    } else if (!this.setState.showList) {
      this.setState({ showList: true, whiteIcon: true });
    }
  }

  handleSearchValueChange(event) {
    const { value } = event.target;
    this.setState({ searchInputValue: value });
    if (this.state.searchInputValue.length > 2) {
      Request.getSearchHints(this.state.searchInputValue, (response) => {
        this.setState({ hints: response.hints });
      });
    }
  }

  searchClick(event) {
    if (event) {
      event.preventDefault();
    }
    this.props.searchValueFetched(this.state.searchInputValue.toLowerCase());
    this.props.hide();
    this.setState({hints: []});
    this.props.history.push("/products");
  }

  render() {
    const searchScreenClass = this.props.showSearch
      ? "searchScreen"
      : "searchScreen searchScreenHidden";

    const cartNumber = {
      border: "solid #357490 2px",
      fontSize: "10px",
      borderRadius: "28px",
      textAlign: "center",
      letterSpacing: 0,
      fontWeight: "bold",
      width: "20px",
      position: "absolute",
      top: "17px",
      right: "85px",
      background: colors.white,
      color: colors.blue,
    };

    return (
      <div
        className={searchScreenClass}
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          backgroundImage: "url(" + background + ")",
          top: "0",
          left: "0",
          transition: "1s",
          zIndex: "1",
        }}
      >
        <div
          className={searchScreenClass}
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(53, 116, 144, 0.8)",
            top: "0",
            left: "0",
            textAlign: "center",
            transition: "1s",
          }}
        >
          <Col md="2" style={{ textAlign: "left" }}>
            <Sygnet style={{ marginTop: "10px" }} />
          </Col>
          <div>
            <form onSubmit={this.searchClick.bind(this)}>
              <Form.Group controlId="search">
                <InputGroup
                  className="searchInput"
                  style={{
                    width: "70vw",
                    margin: "auto",
                    top: "-50px",
                  }}
                >
                  <Form.Control
                    type="text"
                    value={this.state.searchInputValue}
                    onChange={this.handleSearchValueChange.bind(this)}
                  />
                </InputGroup>
                <div
                  className="tips"
                  style={{
                    color: colors.white,
                    width: "70vw",
                    margin: "auto",
                    textAlign: "left",
                    marginTop: "-30px",
                    fontSize: "18px",
                  }}
                >
                  {
                    this.state.hints.map((hint) => (
                      <p onClick={()=>{ this.setState({searchInputValue: hint}, () => {
                        this.searchClick(); this.setState({searchInputValue: ''});
                      }) }} style={{cursor: 'pointer'}}>{hint}</p>
                    ))
                    /* <p>Podpowiedź</p>
                <p>Podpowiedź</p>
                <p>Podpowiedź</p>
                <p>Podpowiedź</p> */
                  }
                </div>
                <Search
                  style={{
                    width: "25px",
                    marginTop: "23px",
                    position: "absolute",
                    top: 0,
                    left: "15vw",
                  }}
                />
              </Form.Group>
            </form>
            <X
              onClick={this.props.hide}
              color={colors.white}
              size={45}
              style={{
                position: "absolute",
                top: "14px",
                right: "10%",
                cursor: "pointer",
              }}
            />
            <Link className="cartNumber" to="/cart">
              <Cart
                style={{
                  width: "25px",
                  marginTop: "24px",
                  position: "absolute",
                  top: 0,
                  right: "7%",
                }}
              />
              {this.props.cart.length > 0 ? (
                <p style={cartNumber}>{this.props.cart.length}</p>
              ) : (
                <></>
              )}
            </Link>
            <Link to="/acount">
              <User
                style={{
                  width: "25px",
                  marginTop: "24px",
                  position: "absolute",
                  top: 0,
                  right: "2.7%",
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchValue: state.searchValue, // (1)
    cart: state.cart,
  };
};
const mapDispatchToProps = { searchValueFetched, cartFetched }; // (2)
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchScreen)
);
