import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import ProductInCart from "../components/cart/ProductInCart";
import CheckoutHeader from "../components/headers/CheckoutHeader";
import InfoButton from "../components/general/InfoButton";
import MobileMenu from "../components/general/MobileMenu";
import { connect } from "react-redux";
import { isMobileFetched, cartFetched, userFetched } from "../actions";
import ListingPage from "./ListingPage";
import Price from "../components/general/Price";

class YourCart extends ListingPage {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: true,
      headerID: 1,
      checkoutPage: true,
      products: [],
    };
  }

  componentDidMount() {
    this.setState({ products: this.props.cart });
  }

  handleQuantityChange(event, id) {
    const { value } = event.target;
    this.setState((state) => {
      if(parseInt(value) > state.products[id].stock){
        state.products[id].quantity = state.products[id].stock;
      } else if(parseInt(value) < 0){
        state.products[id].quantity = 0;
      }else{
      state.products[id].quantity = parseInt(value);
      }
      return state;
    },
      ()=>{
        window.localStorage.setItem('cart', JSON.stringify(this.state.products))
      }
    );
    this.props.cartFetched(this.state.products);
  }

  quantityPlus(id) {
    this.setState((state) => {
      if(parseInt(state.products[id].quantity) + 5 > state.products[id].stock){
        state.products[id].quantity = state.products[id].stock;
      }else{
        state.products[id].quantity = parseInt(state.products[id].quantity) + 5;
      }
      return state;
    }, 
      ()=> this.props.cartFetched(this.state.products)
    );
  }

  quantityMinus(id) {
    this.setState((state) => {
      if(state.products[id].quantity - 5 < 0){
        state.products[id].quantity = 0;
      }else{
        state.products[id].quantity -= 5;
      }
      return state;
    },
      () => this.props.cartFetched(this.state.products)
    );
  }

  removeProduct(id) {
    const products = this.state.products;
    products.splice(id, 1);

    this.setState({ products: products },
      ()=> this.props.cartFetched(this.state.products)
      );
  }

  render() {
    const columnHeader = {
      fontSize: "16px",
      fontFamily: "Lato",
      fontWeight: "300",
    };

    const title = {
      color: colors.blue,
      fontSize: "24px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
    };

    const button = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 3px",
      padding: "3px 30px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      width: "220px",
    };

    const buttonMobile = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 1px",
      padding: "0px 20px",
      borderRadius: "1px",
      fontFamily: "lato",
      fontWeight: "600",
      fontSize: "14px",
      margiTop: "11px",
    };

    return (
      <Container style={{ maxWidth: "100vw" }}>
        <CheckoutHeader headerID={this.state.headerID} />
        <Row>
          <Col style={{ margin: "0 8%" }}>
            <h3 style={title}>Your Cart</h3>
          </Col>
        </Row>

        {this.state.products.length > 0 ? (
          <div>
            <Row
              style={
                this.props.isMobile
                  ? { display: " none" }
                  : { margin: "0 8% 20px 8%" }
              }
            >
              <Col md="6">
                <h4 style={columnHeader}>Product</h4>
              </Col>
              <Col md="3">
                <h4 style={columnHeader}>Your Order</h4>
              </Col>
              <Col md="2">
                <h4 style={columnHeader}>Your Discount</h4>
              </Col>
              <Col md="1">
                <h4 style={columnHeader}>Price</h4>
              </Col>
            </Row>
            {this.state.products.map((product, id) => {
              return (
                <ProductInCart
                  key={id}
                  number={id}
                  name={product.name}
                  image={product.small_image}
                  discount={this.discountPercentage()}
                  price={
                    product.price *
                    product.quantity *
                    ((100 - this.discountPercentage()) * 0.01)
                  }
                  productQuantity={product.quantity}
                  handleInputChange={this.handleQuantityChange.bind(this)}
                  productId={id}
                  quantityPlus={this.quantityPlus.bind(this)}
                  quantityMinus={this.quantityMinus.bind(this)}
                  mobile={this.props.isMobile}
                  removeProduct={() => {
                    this.removeProduct(id);
                  }}
                />
              );
            })}
            <Row
              style={
                this.props.isMobile
                  ? { margin: "0 auto 50px ", width: "220px" }
                  : { margin: "0 8% 50px 8%" }
              }
            >
              <Col md="9"></Col>
              <Col md="2" xs="6">
                <h4
                  style={
                    this.props.mobile
                      ? {
                          fontWeight: "600",
                          fontSize: "16px",
                          fontFamily: "Lato",
                          textAlign: "center",
                        }
                      : {
                          fontWeight: "600",
                          fontSize: "16px",
                          fontFamily: "Lato",
                        }
                  }
                >
                  Sub-total
                </h4>
              </Col>
              <Col md="1" xs="6" style={{ fontWeight: "600" }}>
                <Price price={this.priceSum()} />
              </Col>
            </Row>
            <Row
              style={
                this.props.isMobile
                  ? { marginBottom: "60px" }
                  : { margin: "0 8%" }
              }
            >
              <Col
                style={
                  this.props.isMobile
                    ? { textAlign: "center", marginBottom: "20px" }
                    : {}
                }
              >
                <Link to="/products">
                  <Button
                    style={this.props.mobile ? buttonMobile : button}
                    variant="outline-info"
                  >
                    Continue shopping
                  </Button>
                </Link>
              </Col>
              <Col
                style={
                  this.props.isMobile
                    ? { textAlign: "center" }
                    : { textAlign: "right" }
                }
              >
                <Link to="/delivery">
                  <Button
                    style={this.props.mobile ? buttonMobile : button}
                    variant="outline-info"
                  >
                    Check out
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row
              style={
                this.props.isMobile
                  ? { display: " none" }
                  : { margin: "0 8% 20px 7%" }
              }
            >
              <Col>
                <h4
                  style={{
                    fontFamily: "Lato",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Your cart is empty
                </h4>
              </Col>
            </Row>
            <div style={{textAlign: 'center', marginTop: '30vh'}}> 
              <Link to="/products">
                <Button
                  style={this.props.mobile ? buttonMobile : button}
                  variant="outline-info"
                >
                  Continue shopping
                </Button>
              </Link>
            </div>
          </div>
        )}
        {this.props.isMobile ? <></> : <InfoButton />}
        {this.props.isMobile ? <MobileMenu checkoutPage={this.state.checkoutPage} /> : <></>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
  };
};
const mapDispatchToProps = { isMobileFetched, cartFetched, userFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(YourCart);
