import React from "react";
import { Row, Col, Image, Button, InputGroup, FormControl } from "react-bootstrap";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import colors from "../../colors/colors";
import Request from "../../api/Request";
import Price from "../general/Price";

export default class ProductOnList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     quantity: 0,
    };
  }

  handleQuantityChange(event, id) {
    const { value } = event.target;
    this.setState((state) => {
      if(parseInt(value) > this.props.stock){
        state.quantity = parseInt(this.props.stock);
      }else if(parseInt(value) < 0){
        state.quantity = 0;
      }else{
      state.quantity = parseInt(value);
      }
      return state;
    }, this.adjustQuantity);
  }

  adjustQuantity(state) {
    state = state || this.state;
    let product = { quantity: this.howManyInCart() };
    if ( product.quantity >= this.props.stock) {
      state.quantity = 0;
    } else if ((parseInt(state.quantity) + product.quantity) > this.props.stock) {
      state.quantity = this.props.stock - product.quantity;
    } else if (state.quantity == 0) {
      state.quantity = 1
    }
    this.setState(state);
  }

  howManyInCart() {
    const productId = this.props.productId;
    let quantity = 0;
    const cart = this.props.cart;
    cart.forEach((element, id) => {
      if (element.id == productId) {
        quantity = cart[id].quantity;
      }
    });
    return quantity;
  }

  quantityPlus(id) {
    this.setState((state) => {
      if(parseInt(state.quantity) + 5 > this.props.stock){
        state.quantity = parseInt(this.props.stock);
      }else{
      state.quantity = parseInt(state.quantity) + 5;
      }
      return state;
    }, this.adjustQuantity);
  }

  quantityMinus(id) {
    this.setState((state) => {
      if(this.state.quantity - 5 < 0){
        state.quantity = 0;
      }else{
      state.quantity -= 5;
      }
      return state;
    }, this.adjustQuantity);
  }


  render() {
    const paragraph = {
      fontSize: "16px",
      fontFamily: "Lato",
      fontWeight: "300"
    };

    const paragraphMobile = {
      fontSize: "12px",
      fontFamily: "Lato",
      fontWeight: "300"
    };

    const verticalAlign = {
      padding: "45px 15px"
    };

    const verticalAlignMobile = {
      padding: "45px 15px",
      textAlign: "right"
    };

    const button = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 3px",
      padding: "3px 30px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600"
    };

    const buttonMobile = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 1px",
      padding: "0px 20px",
      borderRadius: "1px",
      fontFamily: "lato",
      fontWeight: "600",
      fontSize: "14px",
      margiTop: "11px"
    };

    return (
      <Row style={this.props.mobile ? { marginTop: "20px" } : {}}>
        <Col md="6" xs="8">
          <Row>
            <Col md="2" style={this.props.mobile ? { display: "none" } : {}}>
              <h4></h4>
            </Col>
            <Col md="7">
              <Row>
                <Col md="4" xs="4">
                  <Image style={{width: '100%'}}  src={Request.realProductImage(this.props.image)} />
                </Col>
                <Col
                  md="8"
                  xs="8"
                  style={
                    this.props.mobile ? verticalAlignMobile : verticalAlign
                  }
                >
                  <p style={this.props.mobile ? paragraphMobile : paragraph}>
                    {this.props.name}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              md="3"
              style={this.props.mobile ? { display: "none" } : verticalAlign}
            >
              <p style={paragraph}>{this.props.stock}</p>
            </Col>
          </Row>
        </Col>
        <Col md="6" xs="4">
          <Row style={this.props.mobile ? {paddingTop : '15px',} : {}}>
            <Col md='3' xs='12'
              style={ this.props.mobile ? {padding:'0', marginLeft: '-10px'} : verticalAlign}
            >
              <p style={paragraph, {marginTop: '-8px'}}>
                <DashCircle className='dashCircleHover' color={colors.blue} style={{ marginTop: "-2px" }} onClick={this.quantityMinus.bind(this)} />
                  <FormControl className = 'productQuantity'
                    value = {this.state.quantity}
                    onChange = {this.handleQuantityChange.bind(this)}
                    aria-describedby="basic-addon1"
                    type='number'
                  />
                <PlusCircle className='plusCircleHover' color={colors.blue} style={{ marginTop: "-2px" }} onClick={this.quantityPlus.bind(this)} />
              </p>
            </Col>
            <Col md='3'
              style={this.props.mobile ? { display: "none" } : verticalAlign}
            >
              <p style={paragraph}>{this.props.discount}%</p>
            </Col>
            <Col md='3'
              style={this.props.mobile ? { display: "none" } : verticalAlign}
            >
              <p style={paragraph}><Price price={this.state.quantity == 0 ? this.props.price : this.props.price * this.state.quantity} /></p>
            </Col>
            <Col md='3' xs='12' style={this.props.mobile ? {padding:'0'} : {padding: "39px 0"}}>
              <Button
                onClick={() => {
                  this.props.showTopInfo(this.state.quantity);
                  debugger;
                  this.adjustQuantity();
                }}
                style={this.props.mobile ? buttonMobile : button}
                variant="outline-info"
                disabled={this.state.quantity <= 0}
              >
                ADD
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
