import React from "react";
import { connect } from "react-redux";
import { userFetched } from "../../actions";

class Price extends React.Component{

    priceExchange(){
       let price = parseFloat(this.props.price);

        if(this.props.user.currency == 'EUR'){
            price = price * 1;
            return price.toFixed(2) + '€'
        }else if(this.props.user.currency == 'PLN'){
            price = price * this.props.user.exchangePLN;
            return price.toFixed(2) + 'zł'
        }else if(this.props.user.currency == 'USD'){
            price =price * this.props.user.exchangeUSD;
            return price.toFixed(2) + '$'
        }
    }

    render(){
        return(
            <>
            { this.priceExchange() }
           </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
      user: state.user,
    };
  };
  const mapDispatchToProps = { userFetched }; // (2)
  export default connect(mapStateToProps, mapDispatchToProps)(Price);
