import React from "react";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import { connect } from "react-redux";
import { currentCategoryFetched, searchValueFetched } from "../../actions";
import { X } from "react-bootstrap-icons";
import Request from '../../api/Request';
//svg
import  { ReactComponent as Sygnet } from '../../svg/sygnet.svg';
import  { ReactComponent as SygnetWhite } from '../../svg/sygnetWhite.svg';
import  { ReactComponent as Cart } from '../../svg/cart.svg';
import  { ReactComponent as CartActive } from '../../svg/cartActive.svg';
import  { ReactComponent as CartWhite } from '../../svg/cartWhite.svg';
import  { ReactComponent as Search } from '../../svg/search.svg';
import  { ReactComponent as SearchWhite } from '../../svg/searchWhite.svg';
import  { ReactComponent as User } from '../../svg/user.svg';
import  { ReactComponent as UserActive } from '../../svg/userActive.svg';
import  { ReactComponent as UserWhite } from '../../svg/userWhite.svg';
import  { ReactComponent as Info } from '../../svg/info.svg';
import  { ReactComponent as InfoWhite } from '../../svg/infoWhite.svg';
import  { ReactComponent as Id } from '../../svg/id.svg'
import  { ReactComponent as Plane } from '../../svg/plane.svg'
import  { ReactComponent as Bill } from '../../svg/bill.svg'
import  { ReactComponent as Tag } from '../../svg/tag.svg'

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteIcon: false,
      showList: false,
      alwaysWhiteIcon: false,
      showInformation: false,
      searchInputValue: "",
      brands: [],
    };
  }

  componentDidMount() {
    Request.getCategories((response) => {
      if (response.success) {
        this.setState({ brands: response.categories });
      }
    });
  }

  hideMobileList() {
    this.setState({ showList: false, whiteIcon: false });
  }

  showHideMobileList() {
    if (this.state.showList) {
      this.setState({ showList: false, whiteIcon: false });
    } else if (!this.setState.showList) {
      this.setState({ showList: true, whiteIcon: true });
    }
  }

  showInformation() {
    this.setState({ showInformation: true });
  }

  hideInformation() {
    this.setState({ showInformation: false });
  }

  handleSearchValueChange(event) {
    const { value } = event.target;
    this.setState({ searchInputValue: value });
  }

  searchClick() {
    this.props.searchValueFetched(this.state.searchInputValue.toLowerCase());
    this.hideMobileList();
  }

  menuItemClick(nr) {
    this.props.currentCategoryFetched(nr);
    this.hideMobileList();
    this.props.searchValueFetched("");
  }

  render() {
    const menuItem = {
      color: colors.white,
      fontSize: "24px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
      padding: "5px 20px ",
    };

    const infoTitle = {
      color: colors.blue,
      fontSize: "16px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
    };

    const showInformationClass = this.state.showInformation
      ? "informtion"
      : "informtion informationHidden";

    const listClass = this.state.showList
      ? "mobileList"
      : "mobileList hiddenMobileList";

    return (
      <div>
        <Row
          style={{
            position: "fixed",
            bottom: "0px",
            paddingBottom: "3px",
            width: "100%",
            zIndex: "4",
          }}
        >
          <Col style={{ textAlign: "center", padding: "0" }}>
          {this.state.alwaysWhiteIcon ||
                this.state.whiteIcon ||
                this.props.helloPage
                  ? <SearchWhite style={{ width: "20px" }} onClick={this.showHideMobileList.bind(this)}/>
                  : <Search style={{ width: "20px" }} onClick={this.showHideMobileList.bind(this)}/>
              }
          </Col>
          <Col style={{ textAlign: "center", padding: "0" }}>
            <Link to="/cart">
              {this.state.alwaysWhiteIcon ||
                  this.state.whiteIcon ||
                  this.props.helloPage
                    ? <CartWhite style={{ width: "20px" }} />
                    : this.props.checkoutPage
                    ? <CartActive style={{ width: "20px" }} />
                    : <Cart style={{ width: "20px" }} />
                }
            </Link>
          </Col>
          <Col style={{ textAlign: "center", padding: "0" }}>
            <Link to="/acount">
                {this.state.alwaysWhiteIcon ||
                  this.state.whiteIcon ||
                  this.props.helloPage
                    ? <UserWhite style={{ width: "20px" }} />
                    : this.props.userPage
                    ? <UserActive style={{ width: "20px" }} />
                    : <User style={{ width: "20px" }} />
                }
            </Link>
          </Col>
          <Col style={{ textAlign: "center", padding: "0" }}>
            {this.state.alwaysWhiteIcon ||
                this.state.whiteIcon ||
                this.props.helloPage
                  ? <InfoWhite onClick={this.showInformation.bind(this)} style={{ width: "20px" }} />
                  : <Info onClick={this.showInformation.bind(this)} style={{ width: "20px" }} />
              }
          </Col>
        </Row>
        {this.state.alwaysWhiteIcon || this.props.helloPage ? (
          <></>
        ) : (
          <Row
            style={{
              backgroundColor: colors.white,
              position: "fixed",
              bottom: "0px",
              paddingBottom: "10px",
              width: "100%",
              zIndex: "0",
              height: "35px",
            }}
          ></Row>
        )}
        <div
          className={listClass}
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(53, 116, 144, 0.9)",
            top: "0",
            left: "0",
            transition: "1s",
          }}
        >
          <div
            className={listClass}
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              top: "0",
              left: "0",
              textAlign: "center",
              transition: "1s",
            }}
          >
            <Col md="2" style={{ textAlign: "center", marginBottom: "50px" }}>
              <SygnetWhite
                style={{ marginTop: "10px", width: "30px" }}
              />
            </Col>
            <div style={{overflowY: 'scroll', height: '61%'}}>
            {this.state.brands.map((brand) => (
                <Link key={brand.id} style={{ textDecoration: "none" }} to="/products">
                  <h3
                    onClick={() => {this.props.currentCategoryFetched(brand.id); this.hideMobileList();}}
                    style={menuItem}
                  >
                    {brand.name}
                  </h3>
                </Link>
            ))}
            </div>
            <Row style={{ padding: "5px 20px" }}>
              <Col xs="2">
                <SearchWhite style={{ width: "20px" }} />
              </Col>
              <Col xs="10" style={{ paddingLeft: "0" }}>
                <Form.Group controlId="userName">
                  <InputGroup
                    className="searchInputMobile"
                    style={{
                      paddingLeft: "0",
                      marginTop: "-12px",
                    }}
                  >
                    <Form.Control
                      type="text"
                      value={this.state.searchInputValue}
                      onChange={this.handleSearchValueChange.bind(this)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Button
                onClick={this.searchClick.bind(this)}
                style={{
                  backgroundColor: colors.white,
                  color: colors.blue,
                  height: "30px",
                  width: "300px",
                  borderRadius: "5px",
                  fontFamily: "Lato",
                  border: "solid 1px white",
                  fontWeight: "800",
                  fontSize: "16px",
                  marginTop: "10px",
                  padding: "0",
                  marginLeft: "calc((100vw - 300px)/2 - 5px)",
                }}
                variant="primary"
                type="submit"
              >
                Search
              </Button>
            </Row>
          </div>
        </div>
        {/* INFO */}
        <div
          className={showInformationClass}
          style={{
            width: "90vw",
            height: "92vh",
            background: colors.white,
            borderRadius: "10px",
            border: "solid 2px",
            borderColor: colors.blue,
            position: "fixed",
            top: "5vh",
            left: "5vw",
            transition: "1s",
            overflowY: 'scroll',
          }}
        >
          <Row style={{padding: "20px" }}>
            <Col>
              <div style={{ display: "flex" }}>
                <p>
                  <Tag style={{ height: 28, marginRight: "10px" }} />
                </p>
                <h4 style={infoTitle}>Loyalty program</h4>
              </div>
              <Row>
                <div style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  fontSize: '12px',
                  marginTop: '0px'}}>
                  <p>Warehouse Partners like <strong>You</strong>, are exceptional customers.</p>
                  <p>That’s why we share unlimited access to our 
                    resources – best in class products, in most competitive prices.</p>
                  <p>Log in and explore wide range of med devices, toxins and professional cosmetics.</p>
                  </div>
                </Row>
            </Col>
          </Row>
          <Row style={{ padding: "20px" }}>
            <Col>
              <div style={{ display: "flex" }}>
                <p>
                  <Bill style={{ height: 20, marginRight: "10px" }} />
                </p>
                <h4 style={infoTitle}>Payment</h4>
              </div>
              <Row>
                <div style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  fontSize: '12px',
                  marginTop: '0px'}}>
                  <p>Trust and relationship – foundations of our business model.</p>
                  <p>As long-term partners, we do not require prepayments, credit cards nor letters of credit.</p>
                  <p>We keep it simple – after fulfilling Your order, we will send You an invoice with convenient payment term. Anything more we can do – we’re here to listen.</p>
                  </div>
                </Row>
            </Col>
          </Row>
          <Row style={{ padding: "10px" }}>
            <Col>
              <div style={{ display: "flex" }}>
                <p>
                  <Plane style={{height: 20, marginRight: "10px" }} />
                </p>
                <h4 style={infoTitle}>Delivery</h4>
              </div>
              <Row>
                <div style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  fontSize: '12px',
                  marginTop: '0px'}}>
                  <p>Flawless delivery – we all love it!</p>
                  <p>All Warehouse shipments leave next business day, with most reliable worldwide courier companies.</p>
                  <p>We have put our trust in hands of FedEx, DHL and UPS, guarantying safe, quick and cost-effective door to door deliveries.</p>
                  </div>
                </Row>
            </Col>
          </Row>
          <Row style={{ padding: "20px" }}>
            <Col>
              <div style={{ display: "flex" }}>
                <p>
                  <Id style={{height: 20, marginRight: "20px" }} />
                </p>
                <h4 style={infoTitle}>Contact</h4>
              </div>
              <Row>
                <div style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  fontSize: '12px',
                  marginTop: '0px'}}>
                  <p><strong>Fräya Group Sp. z o.o.</strong><br/>Świętojańska 43/18<br/>81-391 Gdynia</p>
                  <p>+48 537 906 504</p>
                  <p>info@frayagroup.com</p>
                  </div>
                </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                style={{
                  fontSize: "10px",
                  marginTop: "-5px",
                  marginLeft: "5px",
                }}
              >
                You accepted:
                <Link to="/terms-and-conditions"> Term & Conditions, </Link>
                <Link to="/cokies-information">Cookies, </Link>
                <Link to="/privacy-policy">Pivacy Policy</Link>
              </p>
            </Col>
          </Row>
          <X
            onClick={this.hideInformation.bind(this)}
            color={colors.blue}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCategory: state.currentCategory,
    searchValue: state.searchValue,
  };
};
const mapDispatchToProps = { currentCategoryFetched, searchValueFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
