import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import { Star, StarFill } from "react-bootstrap-icons";
import EmptyHeader from "../components/headers/EmptyHeader";
import InfoButton from "../components/general/InfoButton";
import MobileMenu from "../components/general/MobileMenu";
import { connect } from "react-redux";
import { isMobileFetched, lastOrderIdFetched } from "../actions";
import Request from "../api/Request";
import  { ReactComponent as ArrowLeft } from '../svg/arrowLeft.svg';

class ThankYouPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      stars: 0,
      text: '',
    };
  }

  componentDidMount(){
    if(this.props.lastOrderId === ''){
      this.props.history.push('/')
    }
  }

  sending() {
    Request.orderFeedback({orderId: this.props.lastOrderId, stars: this.state.stars, text: this.state.text}, response => {
      this.setState({ sent: true });
    });
  }

  setStars(number) {
    this.setState({ stars: number });
  }

  render() {
    const title = {
      color: colors.blue,
      fontSize: "24px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
      marginTop: "30px",
      marginBottom: "30px",
    };

    const button = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 3px",
      padding: "3px 30px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      marginBottom: "30px",
      width: "200px",
    };

    const buttonMobile = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 1px",
      padding: "0px 20px",
      borderRadius: "1px",
      fontFamily: "lato",
      fontWeight: "600",
      fontSize: "14px",
      margiTop: "11px",
    };

    return (
      <Container style={{ maxWidth: "100vw" }}>
        <EmptyHeader />
        <Row>
          <Col style={this.props.isMobile ? {} : { margin: "0 8%" }}>
            <h3 style={title}>Thank You</h3>
          </Col>
        </Row>
        {this.state.sent ? (
          <Row style={this.props.isMobile ? {} : { margin: "0 9% 0 7%" }}>
            <Col>
              <p style={{ marginBottom: "0px" }}>
              Packaging process first! 
              </p>
              <p style={{ marginBottom: "5px" }}>
              But, get ready – Your products will be delivered soon. 
              </p>
            </Col>
          </Row>
        ) : (
          <div>
            <Row style={this.props.isMobile ? {} : { margin: "0 9% 0 7%" }}>
              <Col>
                <p style={{ marginBottom: "5px" }}>
                  How do you rate your Fräya experience?
                </p>
                <div style={{ marginBottom: "30px" }}>
                  {this.state.stars >= 1 ? (
                    <StarFill
                      onClick={() => this.setStars(1)}
                      size={35}
                      style={{
                        padding: "3px",
                        paddingLeft: "0",
                        color: colors.blue,
                      }}
                    />
                  ) : (
                    <Star
                      onClick={() => this.setStars(1)}
                      size={35}
                      style={{
                        padding: "3px",
                        paddingLeft: "0",
                        color: colors.blue,
                      }}
                    />
                  )}
                  {this.state.stars >= 2 ? (
                    <StarFill
                      onClick={() => this.setStars(2)}
                      size={35}
                      style={{ padding: "3px", color: colors.blue }}
                    />
                  ) : (
                    <Star
                      onClick={() => this.setStars(2)}
                      size={35}
                      style={{ padding: "3px", color: colors.blue }}
                    />
                  )}
                  {this.state.stars >= 3 ? (
                    <StarFill
                      onClick={() => this.setStars(3)}
                      size={35}
                      style={{ padding: "3px", color: colors.blue }}
                    />
                  ) : (
                    <Star
                      onClick={() => this.setStars(3)}
                      size={35}
                      style={{ padding: "3px", color: colors.blue }}
                    />
                  )}
                  {this.state.stars >= 4 ? (
                    <StarFill
                      onClick={() => this.setStars(4)}
                      size={35}
                      style={{ padding: "3px", color: colors.blue }}
                    />
                  ) : (
                    <Star
                      onClick={() => this.setStars(4)}
                      size={35}
                      style={{ padding: "3px", color: colors.blue }}
                    />
                  )}
                  {this.state.stars >= 5 ? (
                    <StarFill
                      onClick={() => this.setStars(5)}
                      size={35}
                      style={{ padding: "3px", color: colors.blue }}
                    />
                  ) : (
                    <Star
                      onClick={() => this.setStars(5)}
                      size={35}
                      style={{ padding: "3px", color: colors.blue }}
                    />
                  )}
                </div>
                <Form>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>
                      Please leave us feedback in order to improve our services.
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="8"
                      value={this.state.text}
                      onChange={e => this.setState({text: e.target.value})}
                      style={{
                        marginTop: "10px",
                        fontSize: "12px",
                        height: "210px",
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row
              style={
                this.props.isMobile
                  ? { marginBottom: "50px" }
                  : { margin: "30px 8% 0  8%" }
              }
            >
              <Col style={{ textAlign: "center" }}>
                <Button
                  onClick={this.sending.bind(this)}
                  style={this.props.mobile ? buttonMobile : button}
                  variant="outline-info"
                >
                  SEND
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {this.props.isMobile ? <></> : <InfoButton />}
        <Row style={this.props.isMobile ? { display: "none" } : {}}>
          <Col>
            <Link to="/payment">
              <ArrowLeft
                style={{ position: "fixed", top: "120px" }}
              />
            </Link>
          </Col>
        </Row>
        {this.props.isMobile ? <MobileMenu /> : <></>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    lastOrderId: state.lastOrderId
  };
};
const mapDispatchToProps = { isMobileFetched, lastOrderIdFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
