import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched, userFetched } from "../../actions";
import { Link } from "react-router-dom";
import { X } from "react-bootstrap-icons";

class ChangePasswordInfo extends React.Component {
  render() {
    return (
      <div
        style={this.props.isMobile ? {
          width: "300px",
          background: colors.white,
          borderRadius: "10px",
          border: "solid 2px",
          borderColor: colors.blue,
          position: "fixed",
          top: "35vh",
          left: "calc(50vw - 150px)",
          transition: "1s",
        } :
        {
          width: "600px",
          background: colors.white,
          borderRadius: "10px",
          border: "solid 2px",
          borderColor: colors.blue,
          position: "fixed",
          top: "35vh",
          left: "calc(50vw - 300px)",
          transition: "1s",
        }}
      >
        <Row>
          <Col>
            <p style={{ textAlign: "center", padding: "15px", margin: "0" }}>
              We are glad that You have chosen Fraya, Mr.<span style={{textTransform: 'capitalize'}}>{this.props.user.name}</span>.
              <br />
              You can always change your password in the{" "}
              <Link style={{color: colors.blue}} onClick={this.props.setChangePasswordInfo} to="acount">settings.</Link>
              <br />
              We wish you successful shopping!
            </p>
          </Col>
        </Row>
        <X
          onClick={this.props.setChangePasswordInfo}
          color={colors.blue}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    user: state.user,
  };
};
const mapDispatchToProps = { isMobileFetched, userFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordInfo);
