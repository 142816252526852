import React from 'react';
import { Row, Col } from "react-bootstrap";
import {Link} from "react-router-dom";
import SearchScreen from '../general/SearchScreen';
import { connect } from "react-redux";
import { isMobileFetched, cartFetched } from "../../actions";
import colors from '../../colors/colors';
import  { ReactComponent as Sygnet } from '../../svg/sygnet.svg'
import  { ReactComponent as Cart } from '../../svg/cart.svg'
import  { ReactComponent as Search } from '../../svg/search.svg'
import  { ReactComponent as User } from '../../svg/user.svg'
import  { ReactComponent as UserActive } from '../../svg/userActive.svg'

class EmptyHeader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          showSearch: false,
        };
      }

      hideSearchScreen() {
        this.setState({ showSearch: false });
      }

      showSearchScreen() {
        this.setState({ showSearch: true });
      }

    render(){

        const icon = {
            width: '25px',
            marginTop: '25px'
        }

        const cartNumber = {
          border: 'solid #357490 2px',
          fontSize: '10px',
          borderRadius:'28px',
          textAlign: 'center',
          letterSpacing: 0,
          fontWeight: 'bold',
          width: '20px',
          position: 'relative',
          top: '-30px',
          left: '15px',
          background: colors.white,
          color: colors.blue,
        }

        return(
            <Row>
                <Col md='2' style={this.props.isMobile ? {textAlign: 'center'} : {}}><Link to='/'><Sygnet style={this.props.isMobile ? {marginTop: '10px', width: '30px'} : {marginTop: '10px'}}/></Link></Col>
                <Col style={this.props.isMobile ? {display: 'none'} : {marginTop: '25px'}}>
                    
                </Col>
                <Col md='2' style={this.props.isMobile ? {display: 'none'} : {minWidth: '210px'}}>
                    <Row style={{marginLeft: '30px'}}>
                        <Col><Search onClick={this.showSearchScreen.bind(this)} style={icon}/></Col>
                        <Col><Link className='cartNumber' to='cart'><Cart style={icon}/>{this.props.cart.length > 0 ? <p style={cartNumber}>{this.props.orderPage ? this.props.cartLength : this.props.cart.length}</p> : <></>}</Link></Col>
                        <Col><Link to='acount'>{this.props.acountPage ? <UserActive style={icon} /> : <User style={icon}/>}</Link></Col>
                    </Row>
                </Col>
                <SearchScreen 
                showSearch={this.state.showSearch} 
                hide={this.hideSearchScreen.bind(this)}/>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
  };
};
const mapDispatchToProps = { isMobileFetched, cartFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(EmptyHeader);