import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import { X } from "react-bootstrap-icons";
import  { ReactComponent as InfoWhite } from '../../svg/infoWhite.svg'
import  { ReactComponent as Info } from '../../svg/info.svg'
import  { ReactComponent as Id } from '../../svg/id.svg'
import  { ReactComponent as Plane } from '../../svg/plane.svg'
import  { ReactComponent as Bill } from '../../svg/bill.svg'
import  { ReactComponent as Tag } from '../../svg/tag.svg'

export default class InfoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      showInformation: false,
    };
  }

  showInformation() {
    this.setState({ showInformation: true });
  }

  hideInformation() {
    this.setState({ showInformation: false });
  }

  render() {
    const infoTitle = {
      color: colors.blue,
      fontSize: "24px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
    };

    const showInformationClass = this.state.showInformation
      ? "informtion"
      : "informtion informationHidden";

    return (
      <div>
        <Row style={this.state.isMobile ? { display: "none" } : {}}>
          <Col>
            {this.props.whiteInfo ? 
            <InfoWhite 
            onClick={this.showInformation.bind(this)}
            style={{ position: "fixed", bottom: "20px", cursor: "pointer" }} /> :
            <Info 
            onClick={this.showInformation.bind(this)}
            style={{ position: "fixed", bottom: "20px", cursor: "pointer" }} /> }
          </Col>
        </Row>

        <div
          className={showInformationClass}
          style={{
            width: "76vw",
            height: "82vh",
            background: colors.white,
            borderRadius: "10px",
            border: "solid 2px",
            borderColor: colors.blue,
            position: "fixed",
            top: "10vh",
            left: "12vw",
            transition: "1s",
            paddingTop: '1vh'
          }}
        >
          <Row style={{ height: "40vh", padding: "20px" }}>
            <Col>
              <div style={{ display: "flex" }}>
                <p>
                  <Tag style={{ marginRight: "20px" }} />
                </p>
                <h4 style={infoTitle}>Loyalty program</h4>
              </div>
              <Row>
                <div style={{
                  paddingLeft: '68px',
                  paddingRight: '70px',
                  fontSize: '15px',
                  marginTop: '-18px'}}>
                  <p>Warehouse Partners like <strong>You</strong>, are exceptional customers.</p>
                  <p>That’s why we share unlimited access to our 
                    resources – best in class products, in most competitive prices.</p>
                  <p>Log in and explore wide range of med devices, toxins and professional cosmetics.</p>
                  </div>
                </Row>
            </Col>
            <Col>
              <div style={{ display: "flex" }}>
                <p>
                  <Bill style={{ marginRight: "14px" }} />
                </p>
                <h4 style={infoTitle}>Payment</h4>
              </div>
              <Row>
                <div style={{
                  paddingLeft: '71px',
                  paddingRight: '70px',
                  fontSize: '15px',
                  marginTop: '-10px'}}>
                  <p>Trust and relationship – foundations of our business model.</p>
                  <p>As long-term partners, we do not require prepayments, credit cards nor letters of credit.</p>
                  <p>We keep it simple – after fulfilling Your order, we will send You an invoice with convenient payment term. Anything more we can do – we’re here to listen.</p>
                  </div>
                </Row>
            </Col>
          </Row>
          <Row style={{ height: "40vh", padding: "20px" }}>
            <Col>
              <div style={{ display: "flex" }}>
                <p>
                  <Plane style={{ marginRight: "16px" }} />
                </p>
                <h4 style={infoTitle}>Delivery</h4>
              </div>
              <Row>
                <div style={{
                  paddingLeft: '68px',
                  paddingRight: '70px',
                  fontSize: '15px',
                  marginTop: '-18px'}}>
                  <p>Flawless delivery – we all love it!</p>
                  <p>All Warehouse shipments leave next business day, with most reliable worldwide courier companies.</p>
                  <p>We have put our trust in hands of FedEx, DHL and UPS, guarantying safe, quick and cost-effective door to door deliveries.</p>
                  </div>
                </Row>
            </Col>
            <Col>
              <div style={{ display: "flex" }}>
                <p>
                  <Id style={{ marginRight: "20px" }} />
                </p>
                <h4 style={infoTitle}>Contact</h4>
              </div>
              <Row>
                <div style={{
                  paddingLeft: '71px',
                  paddingRight: '70px',
                  fontSize: '15px',
                  marginTop: '-10px'}}>
                  <p><strong>Fräya Group Sp. z o.o.</strong><br/>Świętojańska 43/18<br/>81-391 Gdynia</p>
                  <p>+48 537 906 904</p>
                  <a href="mailto:info@frayagroup.com">info@frayagroup.com</a>
                  </div>
                </Row>
            </Col>
          </Row>
          <Row>
            <Col><p style={{fontSize: '12px', marginTop: '-25px', marginLeft: '10px'}}>You accepted:  
              <Link style={{color: colors.blue}} to='/terms-and-conditions'> Term & Conditions, </Link>
              <Link style={{color: colors.blue}} to='/cokies-information'>Cookies, </Link>
              <Link style={{color: colors.blue}} to='/privacy-policy'>Pivacy Policy</Link>
              </p></Col>
          </Row>
          <X
            onClick={this.hideInformation.bind(this)}
            color={colors.blue}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    );
  }
}
