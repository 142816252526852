import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import colors from "../../colors/colors";
import { Eye, EyeSlash} from 'react-bootstrap-icons';

export default class PasswordInput extends React.Component {
  render() {
    return (
      <Form.Group className="loginInput" controlId="loginPassword">
        <Form.Label
          style={{
            color: colors.white,
            fontSize: "16px",
            fontFamily: "Lato",
            fontWeight: "300",
            textAlign: "left"
          }}
        >
          {this.props.label}
        </Form.Label>
        <Form.Control type={this.props.type ? 'password' : 'input'} value={this.props.password} onChange={this.props.handleInputChange} />
        <InputGroup.Prepend  style={{
          position: 'relative',
          top: '-46px',
          textAlign: 'right',
          color: colors.white,
          paddingRight: '10px',
          height: '0',
          cursor: 'pointer',
        }}>
          <InputGroup.Text style={{
            display: 'flex',
            marginBottom: '0',
            padding: '0',
            marginTop: '4px',
            color: colors.white,
            border: 'none',
            backgroundColor: colors.blue,
            position: 'absolute',
            right: '5px',
            fontSize: '14px',
          }} 
          onClick={this.props.eyeClick} id="visibilyty">{this.props.type ? <Eye /> : <EyeSlash/>}</InputGroup.Text>
        </InputGroup.Prepend>
      </Form.Group>
    );
  }
}
