import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import UniversalInput from "../components/addressAndDelivery/UniversalInput";
import CountrySelect from "../components/addressAndDelivery/CountrySelect";
import countryCodes from "../components/addressAndDelivery/countryCodes";
import EmptyHeader from "../components/headers/EmptyHeader";
import SingleOrder from "../components/myAcount/SingleOrder";
import InfoButton from "../components/general/InfoButton";
import MobileMenu from "../components/general/MobileMenu";
import { connect } from "react-redux";
import {
  isMobileFetched,
  userFetched,
  historicalOrderFetched,
  cartFetched,
} from "../actions";
import Request from "../api/Request";
import ListingPage from "./ListingPage";
import { ReactComponent as ArrowLeft } from "../svg/arrowLeft.svg";
import InvoiceSettings from "../components/myAcount/InvoiceSettings";
import ShippingSettings from "../components/myAcount/ShippingSettings";
import { Code } from "react-bootstrap-icons";

class MyAcountPage extends ListingPage {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      acountPage: true,
      user: {},
    };
  }

  componentDidMount() {
    this.setState({ user: this.props.user });
    Request.getOrderHistory((response) => {
      if (response.success) {
        this.setState({ order: response.orders });
      }
    });
  }

  handleNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        name: value,
      },
    }));
  }

  handleLastNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        lastName: value,
      },
    }));
  }

  handleEmailChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        email: value,
      },
    }));
  }

  handleCountryNumberChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        countryNumber: value,
      },
    }));
  }

  handlePhoneChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        phone: value,
      },
    }));
  }

  handleAdressChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        adress: value,
      },
    }));
  }

  handleCountryChange(event) {
    const { value } = event.target;
    if(this.state.user.countryNumber == '' || this.state.user.countryNumber == null){
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        country: value,
        countryNumber: '+' + countryCodes[value],
      },
    }));
    }else{
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          country: value,
        },
      }));
    }
  }

  handlePostalCodeChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        postal: value,
      },
    }));
  }

  handleInvoiceCheckChange() {
    if (this.state.user.useDifferentInvoiceData) {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          useDifferentInvoiceData: false,
        },
      }));
    } else {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          useDifferentInvoiceData: true,
        },
      }));
    }
  }

  handleShippingCheckChange() {
    if (this.state.user.useDifferentShipingData) {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          useDifferentShipingData: false,
        },
      }));
    } else {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          useDifferentShipingData: true,
        },
      }));
    }
  }

  handleCompanyNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        companyName: value,
      },
    }));
  }

  handleNipChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        nip: value,
      },
    }));
  }

  handleNewsletterChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        newsletter: value,
      },
    }));
  }

  handleInvoiceCompanyNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        invoiceData: {
          ...prevState.user.invoiceData,
          company_name: value,
        },
      },
    }));
  }

  handleInvoiceAddressChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        invoiceData: {
          ...prevState.user.invoiceData,
          address: value,
        },
      },
    }));
  }

  handleInvoiceNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        invoiceData: {
          ...prevState.user.invoiceData,
          name: value,
        },
      },
    }));
  }

  handleInvoiceCountryChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        invoiceData: {
          ...prevState.user.invoiceData,
          country: value,
        },
      },
    }));
  }

  handleInvoicePostalChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        invoiceData: {
          ...prevState.user.invoiceData,
          post_code: value,
        },
      },
    }));
  }

  handleInvoiceVATChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        invoiceData: {
          ...prevState.user.invoiceData,
          nip: value,
        },
      },
    }));
  }

  handleShippingCompanyNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        shipping: {
          ...prevState.user.shipping,
          company_name: value,
        },
      },
    }));
  }

  handleShippingAddressChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        shipping: {
          ...prevState.user.shipping,
          address: value,
        },
      },
    }));
  }

  handleShippingNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        shipping: {
          ...prevState.user.shipping,
          name: value,
        },
      },
    }));
  }

  handleShippingCountryChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        shipping: {
          ...prevState.user.shipping,
          country: value,
        },
      },
    }));
  }

  handleShippingPostalChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        shipping: {
          ...prevState.user.shipping,
          post_code: value,
        },
      },
    }));
  }

  zlotyOnClick() {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        currency: "PLN",
      },
    }));
  }

  dolarOnClick() {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        currency: "USD",
      },
    }));
  }

  euroOnClick() {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        currency: "EUR",
      },
    }));
  }

  updateUser() {
    this.props.userFetched(this.state.user);
  }

  saveMyData() {
    this.setState({ error: false });
    Request.saveMyData(this.state.user, (response) => {
      if (response.success) {
        this.props.userFetched(this.state.user);
      } else {
        this.setState({ error: true });
      }
    });
  }

  changePasswordOnClick() {
    this.props.history.push("/change-password");
  }

  render() {
    const title = {
      color: colors.blue,
      fontSize: "24px",
      fontFamily: "Lato",
      fontWeight: "300",
      textAlign: "left",
      marginTop: "30px",
      marginBottom: "30px",
    };

    const button = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 3px",
      padding: "3px 30px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      marginBottom: "50px",
      position: "relative",
      top: this.props.isMobile ? '65px' : "201px",
    };

    const currencyButton = {
      color: colors.blue,
      borderColor: colors.blue,
      border: "solid 1px",
      padding: "3px 10px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      marginRight: "10px",
      fontSize: "14px",
      width: "30px",
      height: "30px",
    };

    const currencyButtonActive = {
      color: colors.white,
      borderColor: colors.blue,
      backgroundColor: colors.blue,
      border: "solid 1px",
      padding: "3px 10px",
      borderRadius: "3px",
      fontFamily: "lato",
      fontWeight: "600",
      marginRight: "10px",
      fontSize: "14px",
      width: "30px",
      height: "30px",
    };

    return (
      <Container style={{ maxWidth: "100vw" }}>
        <EmptyHeader acountPage={this.state.acountPage} />
        <Row
          style={
            this.props.isMobile ? { marginBottom: "50px" } : { margin: "0 8%" }
          }
        >
          <Col md="4" xs="12">
            <h3 style={title}>Settings</h3>
            <Form>
              <UniversalInput
                placeholder="Name"
                label="Name"
                id="Name"
                value={this.state.user.name}
                handleInputChange={this.handleNameChange.bind(this)}
              />
              <UniversalInput
                placeholder="Last Name"
                label="Last Name"
                id="LastName"
                value={this.state.user.lastName}
                handleInputChange={this.handleLastNameChange.bind(this)}
              />
              <UniversalInput
                placeholder="E-mail"
                label="E-mail"
                id="E-mail"
                value={this.state.user.email}
                handleInputChange={this.handleEmailChange.bind(this)}
              />
              <UniversalInput
                placeholder="CompanyName"
                label="Company Name"
                id="Company Name"
                value={this.state.user.companyName}
                handleInputChange={this.handleCompanyNameChange.bind(this)}
              />
              <UniversalInput
                placeholder="Address"
                label="Address"
                id="Address"
                value={this.state.user.adress}
                handleInputChange={this.handleAdressChange.bind(this)}
              />
              <Row>
                <Col>
                  <CountrySelect
                    value={this.state.user.country}
                    handleInputChange={this.handleCountryChange.bind(this)}
                  />
                </Col>
                <Col>
                  <UniversalInput
                    placeholder="Postal Code"
                    label="Postal Code"
                    id="PostalCode"
                    value={this.state.user.postal}
                    handleInputChange={this.handlePostalCodeChange.bind(this)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={3} style={{ paddingRight: "0px" }}>
                  <UniversalInput
                    placeholder=""
                    label=""
                    id='CountryNumber'
                    value={this.state.user.countryNumber}
                    handleInputChange={this.handleCountryNumberChange.bind(this)}
                  />
                </Col>
                <Col>
                  <UniversalInput
                    placeholder="Phone number"
                    label="Phone number"
                    id="PhoneNumber"
                    value={this.state.user.phone}
                    handleInputChange={this.handlePhoneChange.bind(this)}
                  />
                </Col>
              </Row>
              <UniversalInput
                placeholder="VAT number"
                label="VAT number"
                id="VATnumber"
                value={this.state.user.nip}
                handleInputChange={this.handleNipChange.bind(this)}
              />
              <div className="InvoiceSettings" style={{ marginTop: "-25px" }}>
                <h3 style={title}>Invoicing details</h3>
                <Form.Check
                  checked={this.state.user.useDifferentInvoiceData}
                  type={"checkbox"}
                  label={`My Invoice Settings don't match my main settings`}
                  onChange={this.handleInvoiceCheckChange.bind(this)}
                  style={{
                    fontSize: "14px",
                    marginBottom: "30px",
                    marginTop: "-15px",
                  }}
                />
                {this.state.user.useDifferentInvoiceData ? (
                  <InvoiceSettings
                    handleInvoiceNameChange={this.handleInvoiceNameChange.bind(
                      this
                    )}
                    handleInvoiceCompanyNameChange={this.handleInvoiceCompanyNameChange.bind(
                      this
                    )}
                    handleInvoiceAddressChange={this.handleInvoiceAddressChange.bind(
                      this
                    )}
                    handleInvoiceCountryChange={this.handleInvoiceCountryChange.bind(
                      this
                    )}
                    handleInvoicePostalChange={this.handleInvoicePostalChange.bind(
                      this
                    )}
                    handleInvoiceVATChange={this.handleInvoiceVATChange.bind(
                      this
                    )}
                    user={this.state.user}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="ShippingSettings" style={{ marginTop: "-10px" }}>
                <h3 style={title}>Shipping details</h3>
                <Form.Check
                  checked={this.state.user.useDifferentShipingData}
                  type={"checkbox"}
                  label={`My Shipping Settings don't match my main settings`}
                  onChange={this.handleShippingCheckChange.bind(this)}
                  style={{
                    fontSize: "14px",
                    marginBottom: "30px",
                    marginTop: "-15px",
                  }}
                />
                {this.state.user.useDifferentShipingData ? (
                  <ShippingSettings
                    handleShippingNameChange={this.handleShippingNameChange.bind(
                      this
                    )}
                    handleShippingCompanyNameChange={this.handleShippingCompanyNameChange.bind(
                      this
                    )}
                    handleShippingAddressChange={this.handleShippingAddressChange.bind(
                      this
                    )}
                    handleShippingCountryChange={this.handleShippingCountryChange.bind(
                      this
                    )}
                    handleShippingPostalChange={this.handleShippingPostalChange.bind(
                      this
                    )}
                    user={this.state.user}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Form>
          </Col>
          <Col
            md="4"
            xs="12"
            style={this.props.isMobile ? {} : { paddingLeft: "84px", marginTop: "58px" }}
          >
            <h3 style={title}></h3>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                fontFamily: "lato",
              }}
            >
              <div
                onClick={this.changePasswordOnClick.bind(this)}
                style={{
                  height: "45px",
                  border: "solid 1px",
                  borderColor: colors.blue,
                  borderRadius: "5px",
                  marginBottom: "16px",
                  boxShadow: "0 0 0 0.2rem #c2d5dd",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    position: "relative",
                    top: "9px",
                  }}
                >
                  Change your password
                </p>
              </div>
            </Link>
            <p
              style={{
                fontSize: "12px",
                color: "#777",
                marginBottom: "0",
                marginTop: "-3px",
              }}
            >
              Preferred currency
            </p>
            <div style={{ marginBottom: "16px" }}>
              <Button
                onClick={this.zlotyOnClick.bind(this)}
                style={
                  this.state.user.currency == "PLN"
                    ? currencyButtonActive
                    : currencyButton
                }
                variant="outline-info"
              >
                zł
              </Button>
              <Button
                onClick={this.dolarOnClick.bind(this)}
                style={
                  this.state.user.currency == "USD"
                    ? currencyButtonActive
                    : currencyButton
                }
                variant="outline-info"
              >
                $
              </Button>
              <Button
                onClick={this.euroOnClick.bind(this)}
                style={
                  this.state.user.currency == "EUR"
                    ? currencyButtonActive
                    : currencyButton
                }
                variant="outline-info"
              >
                €
              </Button>
            </div>
            <Row>
              <Col>
                <div className="form-label-group  deliverySelect-group">
                  <Form.Control
                    as="select"
                    value={this.state.user.newsletter}
                    onChange={this.handleNewsletterChange.bind(this)}
                  >
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </Form.Control>
                  <Form.Label className="deliverySelect">
                    I want to receive new product notification
                  </Form.Label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: "18px" }}>
                  Your discount is now{" "}
                  <span style={{ color: colors.blue }}>
                    {this.discountPercentage()}%
                  </span>
                </p>
              </Col>
            </Row>
            <Row style={this.props.isMobile ? { marginBottom: "60px" } : {}}>
              <Col style={{ textAlign: "center" }}>
                <Button
                  onClick={() => {
                    this.updateUser();
                    this.saveMyData();
                  }}
                  style={button}
                  variant="outline-info"
                >
                  UPDATE USER DATA
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md="4" xs="12" style={this.props.isMobile ? {} : { paddingLeft: "84px" }}>
            <h3 style={title}>Order history</h3>
            {this.state.order.length > 0 ? (
              this.state.order.map((order, id) => {
                return (
                  <SingleOrder
                    key={id}
                    order={order.number}
                    onClick={() => this.props.historicalOrderFetched(order)}
                  />
                );
              })
            ) : (
              <p>Your order history is empty</p>
            )}
          </Col>
        </Row>

        {this.props.isMobile ? <></> : <InfoButton />}
        <Row style={this.props.isMobile ? { display: "none" } : {}}>
          <Col>
            <Link to="/">
              <ArrowLeft style={{ position: "fixed", top: "120px" }} />
            </Link>
          </Col>
        </Row>
        {this.props.isMobile ? (
          <MobileMenu userPage={this.state.acountPage} />
        ) : (
          <></>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    user: state.user,
    historicalOrder: state.historicalOrder,
    cart: state.cart,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  userFetched,
  historicalOrderFetched,
  cartFetched,
}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(MyAcountPage);
