export const isMobileFetched = (isMobile) => ({
    type: 'FETCH_IS_MOBILE',
    isMobile
  });

export const cartFetched = (cart) => { 
  window.localStorage.setItem('cart', JSON.stringify(cart));
  return {
  type: 'FETCH_CART',
  cart,
  }
}

export const userFetched = (user) => ({
  type: 'FETCH_USER',
  user
})

export const currentCategoryFetched = (currentCategory) => ({
  type: 'FETCH_CURRENT_CATEGORY',
  currentCategory
})

export const searchValueFetched = (searchValue) => ({
  type: 'FETCH_SEARCH_VALUE',
  searchValue
})

export const historicalOrderFetched = (historicalOrder) => ({
  type: 'FETCH_HISTORICAL_ORDER',
  historicalOrder
})

export const deliveryInfoFetched = (deliveryInfo) => ({
  type: 'FETCH_DELIVERY_INFO',
  deliveryInfo
})

export const lastOrderIdFetched = (lastOrderId) => ({
  type: 'FETCH_LAST_ORDER_ID',
  lastOrderId
})
