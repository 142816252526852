import React from "react";
import {
  Row,
  Col,
  Image,
  FormControl
} from "react-bootstrap";
import { DashCircle, PlusCircle, Trash } from "react-bootstrap-icons";
import colors from "../../colors/colors";
import Request from "../../api/Request";
import Price from "../general/Price";

export default class ProductInCart extends React.Component {
  render() {
    const paragraph = {
      fontSize: "16px",
      fontFamily: "Lato",
      fontWeight: "300",
    };

    const paragraphMobile = {
      fontSize: "12px",
      fontFamily: "Lato",
      fontWeight: "300"
    };

    const verticalAlign = {
      padding: "45px 15px"
    };

    const verticalAlignMobile = {
      padding: "45px 15px",
      textAlign: "right"
    };

    return (
      <Row style={this.props.mobile ? { marginTop: "20px" } : {margin: '0 8%'}}> 
            <Col md="6" xs='8'>
              <Row>
                {!this.props.mobile ? <Col md="1" xs="1"><p style={{padding: '45px 15px', fontSize: '17px'}}>{this.props.number + 1}.</p></Col> : <></>}
                <Col md="4" xs="4">
                  <Image style={{width: '85%'}} src={Request.realProductImage(this.props.image)} />
                </Col>
                <Col
                  md="7"
                  xs="7"
                  style={
                    this.props.mobile ? verticalAlignMobile : verticalAlign
                  }
                >
                  <p style={this.props.mobile ? paragraphMobile : paragraph}>
                    {this.props.name}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md="3" xs="4"
              style={ this.props.mobile ? { padding: "40px 0px",  } : verticalAlign}>
              <p style={paragraph, {marginTop:'-8px'}}>
                <DashCircle
                  className='dashCircleHover'
                  color={colors.blue}
                  style={{ marginTop: "-2px" }}
                  onClick={() => this.props.quantityMinus(this.props.productId)}
                />
                <FormControl
                  className="productQuantityCart"
                  value={this.props.productQuantity}
                  onChange={event =>
                    this.props.handleInputChange(event, this.props.productId)
                  }
                  aria-describedby="basic-addon1"
                  type="number"
                />
                <PlusCircle
                  className='plusCircleHover' 
                  color={colors.blue}
                  style={{ marginTop: "-2px" }}
                  onClick={() => this.props.quantityPlus(this.props.productId)}
                /> 
                <Trash style={{marginLeft: '10px'}} onClick={this.props.removeProduct}/>
              </p>
            </Col>
            <Col
              md="2"
              style={this.props.mobile ? { display: "none" } : verticalAlign}
            >
              <p style={paragraph}>{this.props.discount}%</p>
            </Col>
            <Col
              md="1"
              style={this.props.mobile ? { } : verticalAlign}
            >
              <p style={ this.props.mobile ? {textAlign: 'center', position: 'relative', top: '-30px', fontWeight: '600' } : ({fontWeight: '600'}, paragraph )}><Price price={this.props.price} /></p>
            </Col>  
         
        
      </Row>
    );
  }
}
