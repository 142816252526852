import React from "react";
import { Container, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import background from "../img/background.png";
import WhiteHeader from "../components/headers/WhiteHeader";
import InfoButton from "../components/general/InfoButton";
import MobileMenu from "../components/general/MobileMenu";
import { connect } from "react-redux";
import ChangePasswordInfo from "../components/hellopage/ChangePasswordInfo";
import { isMobileFetched, userFetched } from "../actions";
import Price from "../components/general/Price";

class HelloPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteInfo: true,
      showInformation: false,
      showChangePasswordInfo: true,
      user: { name: "Kowalski", discount: 5 },
    };
  }

  componentDidMount() {
    let nextLogin = JSON.parse(window.localStorage.getItem('nextLogin'));
    if(nextLogin){
      this.setState({showChangePasswordInfo: false});
    }
  }

  showInformation(){
    this.setState({showInformation: true});
  }

  hideInformation(){
    this.setState({showInformation: false});
  }

  setChangePasswordInfo(){
    this.setState({showChangePasswordInfo: false});
    window.localStorage.setItem('nextLogin', JSON.stringify(true));
  }

  render() {
    const text = {
      color: colors.white,
      fontSize: "28px",
      fontFamily: "Lato",
      fontWeight: "600",
      textAlign: "left",
      marginTop: "28px",
      marginBottom: "28px",
    };

    const textMobile = {
      color: colors.white,
      fontSize: "20px",
      fontFamily: "Lato",
      fontWeight: "600",
      textAlign: "left",
      marginTop: "30px",
      marginBottom: "30px",
    };

    console.log(this.props.isMobile);

    return (
      <Container
        style={{
          maxWidth: "100vw",
          height: "100vh",
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <WhiteHeader />
        <Row style={{ verticalAlign: 'center' }}>
          <Col md="2"></Col>
          <Col className="d-flex align-items-center" style={{height: 'calc(100vh - 140px)' }}>
            <span >
            <p style={this.props.isMobile ? textMobile : text}>Hello, <span style={{textTransform: 'capitalize'}}>{this.props.user.name}</span></p>
            <p style={this.props.isMobile ? textMobile : text}>Thank you for being our loyal partner</p>
            <p style={this.props.isMobile ? textMobile : text}>
              {this.props.user.discount && this.props.user.discount.length ? <>Your current discount is <br/>
                {this.props.user.discount.map(discount => <>{discount.percentage}% for orders grater than <Price price={discount.price} /><br/></>)}
              </>: <></>}
            </p>
            <p style={this.props.isMobile ? textMobile : text}>
              Check out our current offer <br />
              or <Link style={{color: colors.white, textDecoration: 'none !important'}} to='/acount'>repeat one of your orders</Link>.
            </p>
            </span>
          </Col>
          <Col md="2"></Col>
        </Row>
        {this.state.showChangePasswordInfo ? <ChangePasswordInfo setChangePasswordInfo={this.setChangePasswordInfo.bind(this)}/> : <></>}
        {this.props.isMobile ? <></> : <InfoButton whiteInfo={this.state.whiteInfo}/> }
        {this.props.isMobile ? <MobileMenu helloPage={true}/> : <></> }
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    user: state.user,
  };
};
const mapDispatchToProps = { isMobileFetched, userFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(HelloPage);